import {
  fetchAllSystemConfigs,
  updateSystemConfig
} from 'pages/administration/setup/actions';
import {
  WithPermissions,
  Resource,
  Action,
  ProtectedButton
} from 'components/Authorization';
import TitleWrapper from 'components/TitleWrapper';
import { FunctionComponent, useEffect, useState } from 'react';
import SetupForm from 'pages/administration/setup/components/Form';
import { useDispatch } from 'react-redux';
import { SetupFormData } from 'pages/administration/setup/formData';
import Loading from 'components/Loading';
import EmptyState from 'components/EmptyState';
import toggleNotification from 'actions/notifications';
import i18n from 'i18n';
import ContentBox from 'components/ContentBox';
import ContentBoxItem from 'components/ContentBoxItem';

const TOTAL_FETCH_ATTEMPTS = 3;

const Setup: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [configData, setConfigData] = useState<SetupFormData>();
  const [noConfigData, setNoConfigData] = useState<boolean>(false);
  const [fetchAttempts, setFetchAttempts] = useState(0);

  const fetchSetConfigData = () => {
    fetchAllSystemConfigs()
      .then(({ data }) => {
        if (data.length > 0) {
          setConfigData(c => {
            return {
              ...c,
              ...data[0]
            };
          });
        }
        setNoConfigData(true);
      })
      .catch(() => {
        setTimeout(() => {
          setFetchAttempts(r => r + 1);
        }, 500);
      });
  };

  useEffect(() => {
    if (fetchAttempts < TOTAL_FETCH_ATTEMPTS) fetchSetConfigData();
    else
      dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
  }, [fetchAttempts, dispatch]);

  const onSave = (formData: SetupFormData) => {
    updateSystemConfig(formData)
      .then(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.setup.updateSuccess'),
            'success'
          )
        );
      })
      .catch(() => {
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
      });
  };

  if (configData)
    return (
      <ContentBox>
        <ContentBoxItem>
          <TitleWrapper
            title={i18n.t('links.sidebarNestedItems.administration.setup')}
          />
          <ProtectedButton
            lnsResource={Resource.SYSTEM_SETUP}
            lnsAction={Action.ADMIN}
            color="secondary"
            variant="contained"
            onClick={() => onSave(configData)}
          >
            {i18n.t('pages.administration.setup.dialog.openBtnLabel')}
          </ProtectedButton>
        </ContentBoxItem>

        <ContentBoxItem>
          <WithPermissions
            lnsResource={Resource.SYSTEM_SETUP}
            lnsAction={Action.EDIT}
          >
            <SetupForm config={configData} setConfigData={setConfigData} />
          </WithPermissions>
        </ContentBoxItem>
      </ContentBox>
    );
  if (noConfigData)
    return (
      <EmptyState
        title={i18n.t('page.administration.setup.emptyState.title')}
        subTitle={i18n.t('page.administration.setup.emptyState.subTitle')}
      />
    );
  if (fetchAttempts === 0)
    return (
      <EmptyState
        title={i18n.t('notifications.apiError')}
        subTitle={i18n.t('notifications.apiError')}
      />
    );
  return <Loading />;
};

export default Setup;

import { makeStyles } from '@material-ui/core';

const signInStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  checkbox: {
    marginTop: -theme.spacing(1.5)
  }
}));

export default signInStyles;

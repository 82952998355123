import { FunctionComponent } from 'react';
import TableBuilder from 'components/Table';
import i18n from 'i18n';
import TitleWrapper from 'components/TitleWrapper';
import ContentBox from 'components/ContentBox';
import ContentBoxItem from 'components/ContentBoxItem';

const SocialMediaFeeds: FunctionComponent = () => {
  const rows = [i18n.t('pages.administration.socialMediaFeeds.tableRow.name')];

  const cells = [
    {
      fields: {
        name: { value: 'Facebook' }
      }
    },
    {
      fields: {
        name: { value: 'Instagram' }
      }
    },
    {
      fields: {
        name: { value: 'Twitter' }
      }
    }
  ];

  return (
    <ContentBox>
      <ContentBoxItem>
        <TitleWrapper
          title={i18n.t(
            'links.sidebarNestedItems.administration.socialMediaFeeds'
          )}
        />
      </ContentBoxItem>
      <TableBuilder rows={rows} cells={cells} />
    </ContentBox>
  );
};

export default SocialMediaFeeds;

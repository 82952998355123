import type { ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import contentStyles from 'styles/contentBox';

interface ContentBoxI {
  title?: string;
  children?: ReactNode | ReactNode[];
}

const ContentBox: FunctionComponent<ContentBoxI> = ({
  title,
  children
}: ContentBoxI) => {
  const classes = contentStyles();

  return (
    <Box className={classes.contentBox}>
      {title && <Typography>{title}</Typography>}
      {Array.isArray(children) ? children.map(item => item) : children}
    </Box>
  );
};

export default ContentBox;

ContentBox.defaultProps = {
  title: undefined,
  children: undefined
};

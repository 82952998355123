import { FunctionComponent, useState } from 'react';
import { Dialog } from '@material-ui/core';
import { setStoryTypes } from 'actions/default';
import {
  createStoryType,
  deleteStoryType,
  updateStoryType
} from 'actions/storyTypes';
import { Description } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import toggleNotification from 'actions/notifications';
import Form from 'pages/administration/story-types/components/Form';
import StoryTypeList from 'pages/administration/story-types/components/List';
import i18n from 'i18n';
import TitleWrapper from 'components/TitleWrapper';
import { ProtectedButton, Resource, Action } from 'components/Authorization';
import ContentBox from 'components/ContentBox';
import ContentBoxItem from 'components/ContentBoxItem';
import Search from 'components/Search';
import Detail from './components/Detail';

const StoryTypes: FunctionComponent = () => {
  const { storyTypes } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  const dispatch = useDispatch();
  const [showStoryType, toggleShowStoryType] = useState<$Lns.StoryType>();
  const [editStoryType, setEditStoryType] = useState<$Lns.StoryType>();

  const [filteredStoryTypes, setFilteredStoryTypes] =
    useState<$Lns.StoryType[]>(storyTypes);

  const onAdd = () => {
    setEditStoryType({
      guid: '',
      durationCalculationMethod: 0,
      name: '',
      key: '',
      clipType: 0,
      isPrompterRequired: false,
      isCgRequired: false
    } as $Lns.StoryType);
  };

  const onEdit = (storyType: $Lns.StoryType) => {
    setEditStoryType(storyType);
  };

  const onEditCancel = () => {
    setEditStoryType(undefined);
  };

  const onSave = async (formData: $Lns.StoryType) => {
    let updatedStoryTypes: $Lns.StoryType[] = [];
    if (!editStoryType) return;

    const storyType = {
      name: formData.name,
      key: formData.key,
      clipType: formData.clipType,
      durationCalculationMethod: formData.durationCalculationMethod,
      isPrompterRequired: formData.isPrompterRequired,
      isCgRequired: formData.isCgRequired
    } as $Lns.StoryType;

    if (formData.guid) {
      storyType.guid = formData.guid;
      updateStoryType(storyType).then(({ data }) => {
        updatedStoryTypes = storyTypes.map(st => {
          if (st.guid === formData.guid) {
            return {
              ...st,
              ...data
            };
          }
          return st;
        });
        dispatch(setStoryTypes(updatedStoryTypes));
        setFilteredStoryTypes(updatedStoryTypes);
        dispatch(
          toggleNotification(
            i18n.t('notifications.storyTypes.updateSuccess'),
            'success'
          )
        );
      });
    } else {
      updatedStoryTypes = [storyType, ...storyTypes];
      createStoryType(storyType).then(({ data }) => {
        setFilteredStoryTypes([data, ...filteredStoryTypes]);
        dispatch(setStoryTypes([data, ...storyTypes]));
        dispatch(
          toggleNotification(
            i18n.t('notifications.storyTypes.createSuccess'),
            'success'
          )
        );
      });
    }
    setEditStoryType(undefined);
  };

  const onDelete = (storyType: $Lns.StoryType) => {
    deleteStoryType(storyType).then(() => {
      const updatedStoryTypes = storyTypes.filter(
        st => st.guid !== storyType.guid
      );
      dispatch(
        toggleNotification(
          i18n.t('notifications.storyTypes.deleteSuccess'),
          'success'
        )
      );
      dispatch(setStoryTypes(updatedStoryTypes));
    });
  };

  const onShow = (storyType: $Lns.StoryType) => {
    toggleShowStoryType(storyType);
  };

  const onClose = () => {
    toggleShowStoryType(undefined);
  };

  return (
    <>
      <ContentBox>
        <ContentBoxItem>
          <TitleWrapper
            title={i18n.t('links.sidebarNestedItems.administration.storyTypes')}
            subText={i18n.t('pages.stories.formLabel.title')}
            icon={<Description color="primary" />}
          />
          <ProtectedButton
            lnsResource={Resource.STORY_TYPE}
            lnsAction={Action.CREATE}
            color="secondary"
            variant="contained"
            onClick={onAdd}
          >
            {i18n.t('pages.administration.storyTypes.button.add')}
          </ProtectedButton>
        </ContentBoxItem>
        <ContentBoxItem>
          <div />
          <Search
            fields={storyTypes}
            searchKeys={['key', 'name']}
            filterFields={setFilteredStoryTypes}
          />
        </ContentBoxItem>
        <StoryTypeList
          storyTypes={filteredStoryTypes}
          onEdit={onEdit}
          onDelete={onDelete}
          onShow={onShow}
        />
      </ContentBox>
      {editStoryType && (
        <Dialog
          onClose={onEditCancel}
          aria-labelledby="simple-dialog-title"
          open
        >
          <Form
            onSave={onSave}
            storyType={editStoryType}
            onCancel={onEditCancel}
          />
        </Dialog>
      )}
      {showStoryType && <Detail storyType={showStoryType} onClose={onClose} />}
    </>
  );
};

export default StoryTypes;

import { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import NewswireStories from 'pages/newswires/components/NewswireStories';
import {
  fetchNewswireServiceStories,
  fetchNewswireServicesByGuid
} from 'pages/newswires/actions';
import EmptyState from 'components/EmptyState';
import i18n from 'i18n';
import { Grid } from '@material-ui/core';
import { RssFeed } from '@material-ui/icons';
import TitleWrapper from 'components/TitleWrapper';
import TitleBarWrapper from 'components/TitleBarWrapper';

const NewswiresContainer: FunctionComponent = () => {
  const { guid } = useParams();

  const [newswireServiceStories, setNewswireServiceStories] = useState<
    $Lns.NewswireStory[]
  >([]);

  const [newswireService, setNewswireService] =
    useState<$Lns.NewswireService>();

  const [selectedNewswireStory, setSelectedNewswireStory] =
    useState<$Lns.NewswireStory>();

  useEffect(() => {
    fetchNewswireServiceStories(guid).then(({ data }) => {
      setNewswireServiceStories(data);
    });

    fetchNewswireServicesByGuid(guid).then(({ data }) => {
      setNewswireService(data);
    });
  }, [guid]);

  if (newswireServiceStories.length < 1)
    return (
      <EmptyState
        title={i18n.t('pages.administration.newswireStories.emptyState.title')}
        subTitle={i18n.t(
          'pages.administration.newswireStories.emptyState.subTitle'
        )}
      />
    );

  const onRowClick = (rowItem: { guid: { value: string } }) => {
    const newswire = newswireServiceStories.find(
      (nw: $Lns.NewswireStory) => nw.guid === rowItem.guid.value
    );

    setSelectedNewswireStory(newswire);
  };

  const onHandleClose = () => {
    setSelectedNewswireStory(undefined);
  };

  return (
    <Grid container spacing={2}>
      <TitleBarWrapper>
        <Grid item xs={12}>
          <TitleWrapper
            title={newswireService?.name || 'Unnamed'}
            subText={i18n.t('pages.stories.formLabel.title')}
            icon={<RssFeed color="primary" />}
          />
        </Grid>
      </TitleBarWrapper>
      <Grid item xs={12}>
        <NewswireStories
          newswireStories={newswireServiceStories}
          selectedNewswireStory={selectedNewswireStory}
          onHandleClose={onHandleClose}
          onRowClick={onRowClick}
        />
      </Grid>
    </Grid>
  );
};

export default NewswiresContainer;

import { Dialog, Button } from '@material-ui/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchMediaItemsFromLS,
  updateMediaItemsInLS
} from 'pages/administration/media/actions';
import { FormData } from 'pages/administration/media/formData';
import EmptyState from 'components/EmptyState';
import Form from 'pages/administration/media/components/Form';
import MediaItemList from 'pages/administration/media/components/List';
import MediaItemDetail from 'pages/administration/media/components/MediaItemDetail';
import toggleNotification from 'actions/notifications';
import i18n from 'i18n';
import shortid from 'shortid';
import TitleWrapper from 'components/TitleWrapper';
import Search from 'components/Search';
import ContentBox from 'components/ContentBox';
import ContentBoxItem from 'components/ContentBoxItem';

const Media: FunctionComponent = () => {
  const [mediaItems, setMediaItems] = useState<$Lns.MediaItem[]>([]);
  const [mediaItemsOrig, setMediaItemsOrig] = useState<$Lns.MediaItem[]>([]);
  const [editMediaItem, setEditMediaItem] = useState<$Lns.MediaItem>();
  const [showMediaItem, setShowMediaItem] = useState<$Lns.MediaItem>();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchMediaItemsFromLS().then(({ data }) => {
      if (data) {
        setMediaItems(data);
        setMediaItemsOrig(data);
      }
    });
  }, []);

  const onAdd = () => {
    setEditMediaItem({
      name: '',
      type: '',
      sourceUrl: '',
      guid: ''
    });
  };

  const onEditCancel = () => {
    setEditMediaItem(undefined);
  };

  const onSave = (formData: FormData) => {
    let updatedMediaItems: $Lns.MediaItem[] = [];
    if (!formData.guid) {
      const newMediaItem = {
        ...formData,
        guid: shortid.generate()
      };
      updatedMediaItems = [newMediaItem, ...mediaItems];
    } else {
      updatedMediaItems = mediaItems.map(mi => {
        if (mi.guid === formData.guid) {
          return {
            ...formData,
            mi
          };
        }
        return mi;
      });
    }

    updateMediaItemsInLS(updatedMediaItems);
    onEditCancel();
    setMediaItems(updatedMediaItems);
    dispatch(
      toggleNotification(
        i18n.t('notifications.mediaItems.createSuccess'),
        'success'
      )
    );
  };

  const onEdit = (mediaItem: $Lns.MediaItem) => {
    setEditMediaItem(mediaItem);
  };

  const onShow = (mediaItem: $Lns.MediaItem) => {
    setShowMediaItem(mediaItem);
  };

  const onClose = () => {
    setShowMediaItem(undefined);
  };

  const onRemove = (mediaItem: $Lns.MediaItem) => {
    const updatedMediaItems = mediaItems.filter(
      mi => mi.guid !== mediaItem.guid
    );
    updateMediaItemsInLS(updatedMediaItems);
    setMediaItems(updatedMediaItems);
    dispatch(
      toggleNotification(
        i18n.t('notifications.mediaItems.removeSuccess'),
        'success'
      )
    );
  };

  if (!mediaItemsOrig.length) {
    return (
      <EmptyState
        title={i18n.t('pages.administration.media.emptyState.title')}
        subTitle={i18n.t('pages.administration.media.emptyState.subTitle')}
      />
    );
  }

  return (
    <>
      <ContentBox>
        <ContentBoxItem>
          <TitleWrapper
            title={i18n.t('links.sidebarNestedItems.administration.media')}
          />
          <Button color="secondary" variant="contained" onClick={onAdd}>
            {i18n.t('pages.administration.media.button.add')}
          </Button>
        </ContentBoxItem>
        <ContentBoxItem>
          <div />
          <Search
            fields={mediaItemsOrig}
            searchKeys={['guid', 'name']}
            filterFields={setMediaItems}
          />
        </ContentBoxItem>
        <MediaItemList
          onShow={onShow}
          mediaItems={mediaItems}
          onEdit={onEdit}
          onRemove={onRemove}
        />
      </ContentBox>
      {editMediaItem && (
        <Dialog
          onClose={onEditCancel}
          aria-labelledby="simple-dialog-title"
          open
        >
          <Form
            onSave={onSave}
            mediaItem={editMediaItem}
            onCancel={onEditCancel}
          />
        </Dialog>
      )}
      {showMediaItem && (
        <MediaItemDetail mediaItem={showMediaItem} onClose={onClose} />
      )}
    </>
  );
};

export default Media;

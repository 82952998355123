import { FunctionComponent, useEffect, useState, useCallback } from 'react';
import { Dialog } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  fetchLocations,
  createLocation,
  updateLocation,
  deleteLocation
} from 'pages/administration/locations/actions';
import LocationList from 'pages/administration/locations/components/List';
import Form from 'pages/administration/locations/components/Form';
import toggleNotification from 'actions/notifications';
import i18n from 'i18n';
import TitleWrapper from 'components/TitleWrapper';
import BtnWrapper from 'components/BtnWrapper';
import { ProtectedButton, Resource, Action } from 'components/Authorization';
import ContentBoxItem from 'components/ContentBoxItem';
import ContentBox from 'components/ContentBox';
import Search from 'components/Search';

const Locations: FunctionComponent = () => {
  const [locations, setLocations] = useState<$Lns.Location[]>([]);
  const [locationsOrig, setLocationsOrig] = useState<$Lns.Location[]>([]);

  const [editTemplate, setEditTemplate] = useState<$Lns.Location>();
  const dispatch = useDispatch();

  const fetchSetLocations = useCallback(() => {
    fetchLocations()
      .then(({ data }) => {
        setLocations(data);
        setLocationsOrig(data);
      })
      .catch(() => {
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
      });
  }, [dispatch]);

  useEffect(() => {
    fetchSetLocations();
  }, [fetchSetLocations]);

  const onEditCancel = () => {
    setEditTemplate(undefined);
  };

  const onEdit = (location: $Lns.Location) => {
    setEditTemplate(location);
  };

  const onSaveEdit = (formData: $Lns.Location) => {
    updateLocation(formData)
      .then(() => {
        fetchSetLocations();
        setEditTemplate(undefined);
        dispatch(
          toggleNotification(
            i18n.t('notifications.locations.updateSuccess'),
            'success'
          )
        );
      })
      .catch(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.locations.apiError'),
            'error'
          )
        );
      });
  };

  const onAdd = () => {
    setEditTemplate({
      city: '',
      country: ''
    } as $Lns.Location);
  };

  const onSave = (formData: $Lns.Location) => {
    if (formData.guid) {
      onSaveEdit(formData);
    } else {
      onCreate(formData);
    }
  };

  const onCreate = (formData: $Lns.Location) => {
    createLocation(formData)
      .then(() => {
        fetchSetLocations();
        setEditTemplate(undefined);
        dispatch(
          toggleNotification(
            i18n.t('notifications.locations.createSuccess'),
            'success'
          )
        );
      })
      .catch(() => {
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
      });
  };

  const onDelete = (deleteTarget: $Lns.Location) => {
    deleteLocation(deleteTarget)
      .then(() => {
        fetchSetLocations();
        dispatch(
          toggleNotification(
            i18n.t('notifications.locations.deleteSuccess'),
            'success'
          )
        );
      })
      .catch(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.locations.deleteError'),
            'error'
          )
        );
      });
  };

  return (
    <>
      <ContentBox>
        <ContentBoxItem>
          <TitleWrapper
            title={i18n.t('links.sidebarNestedItems.administration.locations')}
          />
          <BtnWrapper>
            <ProtectedButton
              lnsResource={Resource.LOCATION}
              lnsAction={Action.CREATE}
              color="secondary"
              variant="contained"
              onClick={onAdd}
            >
              {i18n.t('pages.administration.locations.button.add')}
            </ProtectedButton>
          </BtnWrapper>
        </ContentBoxItem>
        <ContentBoxItem>
          <div />
          <Search
            fields={locationsOrig}
            searchKeys={['guid', 'city', 'country']}
            filterFields={setLocations}
          />
        </ContentBoxItem>
        <LocationList
          locations={locations}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </ContentBox>
      {editTemplate && (
        <Dialog
          onClose={onEditCancel}
          aria-labelledby="simple-dialog-title"
          open
        >
          <Form
            location={editTemplate}
            onSave={onSave}
            onCancel={onEditCancel}
          />
        </Dialog>
      )}
    </>
  );
};

export default Locations;

import { makeStyles } from '@material-ui/core';
import colors from 'styles/colors';

export const systemSetupStyles = makeStyles(() => ({
  heading: {
    fontSize: 18,
    color: colors.gray[800],
    borderBottom: `2px solid ${colors.gray[800]}`,
    marginBottom: '14px',
    marginTop: '14px'
  }
}));

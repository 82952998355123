import type { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core';
import colors from './colors';

const searchStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      paddingRight: 10,
      width: theme.spacing(37),
      border: `1px solid ${colors.gray[300]}`,
      borderRadius: theme.spacing(1),
      background: colors.base.white,
      display: 'flex',
      alignItems: 'center'
    },
    input: {
      flex: 1,
      marginLeft: theme.spacing(1)
    },
    iconButton: {
      padding: 10
    },
    divider: {
      height: 28,
      margin: 4
    }
  })
);

export default searchStyles;

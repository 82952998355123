import { createStyles, makeStyles } from '@material-ui/core';
import colors from './colors';

export const generalStyle = makeStyles(theme =>
  createStyles({
    textUppercase: {
      '& input': {
        textTransform: 'uppercase'
      }
    },
    uploadZone: {
      border: '2px dashed',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      height: `${theme.spacing(12)}px`
    },
    gridRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'stretch',
      alignItems: 'end',
      '&>div': {
        padding: theme.spacing(1)
      }
    },
    selectLabel: {
      marginBottom: 6,
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 500,
      color: colors.gray[700]
    }
  })
);

import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Grid, Button } from '@material-ui/core';
import i18n from 'i18n';
import toggleNotification from 'actions/notifications';
import Loading from 'components/Loading';
import EmptyState from 'components/EmptyState';
import List from 'pages/administration/peripheral-settings/protocols/components/List';
import Form from 'pages/administration/peripheral-settings/protocols/components/Form';
import ItemDetail from 'pages/administration/peripheral-settings/protocols/components/ItemDetail';
import {
  fetchProtocols,
  createProtocol,
  updateProtocol,
  deleteProtocol
} from 'pages/administration/peripheral-settings/actions';
import { FormData } from 'pages/administration/peripheral-settings/protocols/formData';
import TitleWrapper from 'components/TitleWrapper';
import TitleBarWrapper from 'components/TitleBarWrapper';
import BtnWrapper from 'components/BtnWrapper';
import { SettingsInputSvideo } from '@material-ui/icons';

const Protocols: FunctionComponent = () => {
  const dispatch = useDispatch();

  const handleApiError = () => {
    dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
  };

  const [protocols, setProtocols] = useState<$Lns.Protocol[]>();

  const fetchSetProtocols = () => {
    fetchProtocols().then(({ data }) => {
      setProtocols(data);
    });
  };
  useEffect(() => {
    fetchSetProtocols();
  }, []);

  const [editProtocol, setEditProtocol] = useState<$Lns.Protocol>();
  const [showProtocol, setShowProtocol] = useState<$Lns.Protocol>();

  const onAdd = () => {
    const newProtocol = {} as $Lns.Protocol;
    setEditProtocol(newProtocol);
  };

  const onEdit = (protocolItem: $Lns.Protocol) => {
    setEditProtocol(protocolItem);
  };

  const onSave = (formData: FormData) => {
    if (formData.guid === undefined) {
      createProtocol(formData)
        .then(() => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.protocols.addSuccess'),
              'success'
            )
          );
          fetchSetProtocols();
        })
        .catch(handleApiError);
    } else {
      updateProtocol(formData)
        .then(() => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.protocols.updateSuccess'),
              'success'
            )
          );
          fetchSetProtocols();
        })
        .catch(handleApiError);
    }
    setEditProtocol(undefined);
  };

  const onEditCancel = () => setEditProtocol(undefined);

  const onDelete = (protocolItem: $Lns.Protocol) => {
    deleteProtocol(protocolItem)
      .then(() => {
        dispatch(
          toggleNotification(
            i18n.t('pages.administration.protocols.error'),
            'error'
          )
        );
        fetchSetProtocols();
        setEditProtocol(undefined);
      })
      .catch(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.protocols.deleteSuccess'),
            'success'
          )
        );
      });
  };

  const onShow = (templateItem: $Lns.PeripheralTemplate) => {
    setShowProtocol(templateItem);
  };

  const onClose = () => setShowProtocol(undefined);

  return (
    <Grid container spacing={2}>
      <TitleBarWrapper>
        <Grid item xs={6}>
          <TitleWrapper
            title={i18n.t('links.sidebarNestedItems.administration.protocols')}
            subText={i18n.t('pages.stories.formLabel.title')}
            icon={<SettingsInputSvideo color="primary" />}
          />
        </Grid>
        <Grid item xs={6}>
          <BtnWrapper>
            <Button color="secondary" variant="contained" onClick={onAdd}>
              {i18n.t('pages.administration.protocols.button.add')}
            </Button>
          </BtnWrapper>
        </Grid>
      </TitleBarWrapper>
      <Grid item xs={12}>
        {!protocols && <Loading />}
        {protocols && protocols.length === 0 && (
          <EmptyState
            title={i18n.t('pages.administration.protocols.emptyState.title')}
            subTitle={i18n.t(
              'pages.administration.protocols.emptyState.subTitle'
            )}
          />
        )}
        {protocols && protocols.length > 0 && (
          <List
            protocols={protocols}
            onShow={onShow}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        )}
      </Grid>
      {editProtocol && (
        <Dialog
          onClose={onEditCancel}
          aria-labelledby="edit-protocols-dialog"
          fullWidth
          open
        >
          <Form
            protocolItem={editProtocol}
            onSave={onSave}
            onCancel={onEditCancel}
          />
        </Dialog>
      )}
      {showProtocol && (
        <ItemDetail protocolItem={showProtocol} onClose={onClose} />
      )}
    </Grid>
  );
};

export default Protocols;

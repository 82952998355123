import React, { FunctionComponent, useEffect, useState } from 'react';
import toggleNotification from 'actions/notifications';
import Loading from 'components/Loading';
import Stories from 'pages/stories/components/List';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { DropResult } from 'react-beautiful-dnd';
import {
  fetchPublishFolderStoriesByGuid,
  fetchPublishFolders
} from 'actions/publishFolders';
import { Folder } from '@material-ui/icons';
import i18n from 'i18n';
import { Button, Grid } from '@material-ui/core';
import TitleWrapper from 'components/TitleWrapper';
import TitleBarWrapper from 'components/TitleBarWrapper';
import BtnWrapper from 'components/BtnWrapper';

const ShowPublishStories: FunctionComponent = () => {
  const { guid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [stories, setStories] = useState<$Lns.Story[]>([]);
  const [publishFolder, setPublishFolder] = useState<$Lns.PublishFolder>();
  const [isLoading, toggleLoading] = useState(false);

  useEffect(() => {
    toggleLoading(true);
    fetchPublishFolderStoriesByGuid(guid)
      .then(({ data }) => {
        toggleLoading(false);
        setStories(data);
      })
      .catch(() => {
        toggleLoading(false);
        dispatch(
          toggleNotification(
            i18n.t('notifications.storyPools.storyFetchError'),
            'error'
          )
        );
      });
    fetchPublishFolders()
      .then(jsonI => {
        toggleLoading(false);
        jsonI.data.forEach(pin => {
          if (pin.guid === guid) {
            setPublishFolder(pin);
          }
        });
      })
      .catch(() => {
        toggleLoading(false);
        dispatch(
          toggleNotification(
            i18n.t('notifications.storyPools.storyFetchError'),
            'error'
          )
        );
      });
  }, [dispatch, guid]);

  if (stories.length < 1 && isLoading) return <Loading />;

  const onDragComplete = (result: DropResult) => {
    // todo replace
    // eslint-disable-next-line
    console.log(result);
  };

  const onEditStory = (story: $Lns.Story) => {
    navigate(`/my-lns/${story.guid}/edit`);
  };

  const gotoAddStory = () => {
    navigate(`/publish-folders/${guid}/edit`);
  };

  return (
    <Grid container direction="row" spacing={2}>
      <TitleBarWrapper>
        <Grid item xs={6}>
          <TitleWrapper
            title={publishFolder?.name || ''}
            subText={i18n.t('pages.stories.formLabel.title')}
            icon={<Folder color="primary" />}
          />
        </Grid>
        <Grid item xs={6}>
          <BtnWrapper>
            <Button
              color="secondary"
              variant="contained"
              onClick={gotoAddStory}
            >
              Edit
            </Button>
          </BtnWrapper>
        </Grid>
      </TitleBarWrapper>
      <Grid item xs={12}>
        <Stories
          stories={stories}
          storyPoolId={guid}
          onEditClick={onEditStory}
          onDragComplete={onDragComplete}
        />
      </Grid>
    </Grid>
  );
};

export default ShowPublishStories;

import { FunctionComponent, useEffect, useState } from 'react';
import StoryPoolList from 'pages/administration/story-pools/components/List';
import {
  createStoryPool,
  updateStoryPool,
  deleteStoryPool,
  fetchStoryPools
} from 'actions/storyPools';
import { Dialog } from '@material-ui/core';
import Form from 'pages/administration/story-pools/components/Form';
import { FormData } from 'pages/story-pools/formData';
import { useDispatch, useSelector } from 'react-redux';
import toggleNotification from 'actions/notifications';
import i18n from 'i18n';
import { setStoryPools } from 'actions/default';
import { ListAlt } from '@material-ui/icons';
import TitleWrapper from 'components/TitleWrapper';
import { ProtectedButton, Resource, Action } from 'components/Authorization';
import ContentBox from 'components/ContentBox';
import ContentBoxItem from 'components/ContentBoxItem';
import Search from 'components/Search';

const StoryPools: FunctionComponent = () => {
  const dispatch = useDispatch();

  const handleApiError = (err: Error) => {
    dispatch(
      toggleNotification(
        err.message || i18n.t('notifications.apiError'),
        'error'
      )
    );
  };

  const storyPools = useSelector(
    (state: $Lns.DefaultState) => state.default.storyPools
  );

  const fetchSetStoryPools = () => {
    fetchStoryPools()
      .then(({ data }) => {
        dispatch(setStoryPools(data));
      })
      .catch(() =>
        dispatch(toggleNotification('Failed to load storypools', 'error'))
      );
  };

  const [editStoryPool, setEditStoryPool] = useState<FormData>();

  const onAdd = () => {
    setEditStoryPool({
      name: '',
      parentPool: null,
      errors: { name: '' }
    });
  };

  const onSave = (formData: FormData) => {
    if (formData.guid === undefined) {
      // creating  a story pool
      createStoryPool(formData as $Lns.StoryPool)
        .then(() => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.storyPools.createSuccess'),
              'success'
            )
          );
          fetchSetStoryPools();
        })
        .catch(handleApiError);
    } else {
      // updating a story pool
      updateStoryPool(formData as $Lns.StoryPool)
        .then(() => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.storyPools.editSuccess'),
              'success'
            )
          );
          fetchSetStoryPools();
        })
        .catch(handleApiError);
    }
    setEditStoryPool(undefined);
  };

  const onDelete = (storyPool: $Lns.StoryPool) => {
    if (storyPool.guid !== undefined) {
      deleteStoryPool(storyPool).then(res => {
        if (res && res.code === 500) {
          dispatch(
            toggleNotification(
              res.message || i18n.t('pages.administration.storyPools.error'),
              'error'
            )
          );
        } else {
          dispatch(
            toggleNotification(
              i18n.t('notifications.storyPools.removeSuccess'),
              'success'
            )
          );
        }
        fetchSetStoryPools();
      });
    }
  };

  const onEditCancel = () => {
    setEditStoryPool(undefined);
  };

  const onEdit = (storyPool: $Lns.StoryPool) => {
    setEditStoryPool({ ...storyPool, errors: { name: '' } });
  };

  useEffect(() => {
    fetchSetStoryPools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentBox>
      <ContentBoxItem>
        <TitleWrapper
          title={i18n.t('links.sidebarNestedItems.administration.storyPools')}
          subText={i18n.t('pages.stories.formLabel.title')}
          icon={<ListAlt color="primary" />}
        />
        <ProtectedButton
          color="secondary"
          variant="contained"
          onClick={onAdd}
          lnsResource={Resource.STORY_POOL}
          lnsAction={Action.CREATE}
        >
          {i18n.t('pages.administration.storyPools.button.add')}
        </ProtectedButton>
      </ContentBoxItem>
      <ContentBoxItem>
        <div />
        <Search
          fields={storyPools}
          searchKeys={['guid', 'name']}
          filterFields={fields =>
            dispatch(setStoryPools(fields as $Lns.StoryPool[]))
          }
        />
      </ContentBoxItem>
      <StoryPoolList
        storyPools={storyPools}
        onDelete={onDelete}
        onEdit={onEdit}
      />

      {editStoryPool && (
        <Dialog onClose={onEditCancel} aria-labelledby="edit-storypool" open>
          <Form
            onSave={onSave}
            storyPool={editStoryPool}
            onCancel={onEditCancel}
          />
        </Dialog>
      )}
    </ContentBox>
  );
};

export default StoryPools;

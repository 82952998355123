import { makeStyles } from '@material-ui/core';
import colors from './colors';

const contentStyles = makeStyles({
  contentBox: {
    height: 'fit-content',
    borderRadius: 12,
    border: `1px solid ${colors.gray[200]}`,
    background: colors.base.white,
    boxShadow: '0px 1px 2px 0px rgba(10, 13, 18, 0.05)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    '&>div:not(:first-child):not(.MuiTableContainer-root)': {
      borderTop: `1px solid ${colors.gray[200]}`
    }
  }
});

export default contentStyles;

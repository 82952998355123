import { Box, IconButton } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import i18n from 'i18n';
import { Search as SearchIcon } from '@material-ui/icons';
import searchStyles from 'styles/search';
import BasicTextField from './BasicTextField';

interface SearchI<T extends Record<string, unknown>> {
  fields: T[];
  searchKeys?: (keyof T)[];
  filterFields?: Dispatch<SetStateAction<T[]>>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Search: FunctionComponent<SearchI<any>> = <
  T extends Record<string, unknown>
>({
  fields,
  searchKeys,
  filterFields
}: SearchI<T>) => {
  const classes = searchStyles();

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const filteredFields = fields.filter(field => {
      const keys = searchKeys ?? (Object.keys(field) as (keyof T)[]);

      return keys.find(key => {
        const prop = field[key];

        if (typeof prop === 'string') {
          return prop.toLowerCase().includes(search.toLowerCase());
        }

        if (typeof prop === 'number') return prop === Number(search);

        return undefined;
      });
    });

    if (filterFields) filterFields(filteredFields);
  };

  return (
    <Box className={classes.search}>
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
      <BasicTextField
        className={classes.input}
        placeholder={i18n.t('placeholders.search')}
        onChange={onSearchFieldChange}
      />
    </Box>
  );
};

export default Search;

Search.defaultProps = { searchKeys: undefined, filterFields: undefined };

import { createStyles, TableCell, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import colors from 'styles/colors';

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'left',
      whiteSpace: 'nowrap'
    },
    head: {
      fontWeight: 600,
      borderTop: `1px solid ${colors.gray[200]}`,
      color: colors.gray[500],
      background: colors.gray[25]
    },
    body: {
      fontSize: theme.typography.fontSize
    }
  })
)(TableCell);

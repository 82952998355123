import { makeStyles } from '@material-ui/core';
import themeStyle from 'styles/theme';
import colors from '../colors';

const aoAccordionStyles = makeStyles(theme => ({
  aoAccordion: {
    background: colors.brand[100],
    borderColor: colors.brand[200]
  },
  header: {
    display: 'flex',
    alignItems: 'start',
    gap: theme.spacing(2)
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '28px',
    color: colors.gray[900]
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 14,
    color: colors.gray[600]
  },
  body: {
    paddingTop: theme.spacing(3),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  bodyHeader: {
    color: colors.gray[700],
    ...themeStyle.typography.smMedium
  },
  separator: {
    height: 1,
    backgroundColor: colors.brand[200]
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  select: {
    background: colors.brand[25],
    border: `1px solid ${colors.brand[200]}`,
    '&:hover': {
      border: `1px solid ${colors.brand[300]}`
    },
    '&:focus': {
      background: `${colors.brand[25]} !important`
    },
    'MuiSelect-root': {
      background: 'none'
    }
  }
}));

export default aoAccordionStyles;

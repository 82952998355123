import { makeStyles } from '@material-ui/core';
import colors from './colors';

const accordionStyles = makeStyles(theme => ({
  accordion: {
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1.5),
    border: `solid 1px ${colors.gray[200]}`,
    boxShadow: 'none',
    '&.Mui-expanded': {
      margin: 0
    },
    '&::before': {
      display: 'none'
    },
    '&:first-child': {
      borderRadius: theme.spacing(1.5)
    },
    '&:last-child': {
      borderRadius: theme.spacing(1.5)
    }
  },
  summary: {
    padding: 0,
    minHeight: 0,
    '&.Mui-expanded': {
      minHeight: 0
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1)
    },
    '& .MuiAccordionSummary-expandIcon': {
      padding: `0 ${theme.spacing(1.5)}px`
    }
  },
  title: {
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 600,
    color: colors.gray[900]
  },
  details: {
    padding: 0
  }
}));

export default accordionStyles;

import { Button, Grid, Tab, Tabs } from '@material-ui/core';
import toggleNotification from 'actions/notifications';
import i18n from 'i18n';
import StoriesTable from 'components/Story/Table';
import StoryPoolTabs from 'pages/story-pools/components/Tabs';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  fetchStoryPoolStories,
  updateStoryPoolStories
} from 'actions/storyPools';
import { fetchUserStories } from 'actions/user';
import TitleWrapper from 'components/TitleWrapper';
import TitleBarWrapper from 'components/TitleBarWrapper';
import BtnWrapper from 'components/BtnWrapper';
import { TabContext, TabPanel } from '@material-ui/lab';
import { generalStyle } from 'pages/rundowns/styles';

const Edit: FunctionComponent = () => {
  const { guid } = useParams();
  const [storyPoolStories, setStoryPoolStories] = useState<$Lns.Story[]>([]);
  const [myStories, setMyStories] = useState<$Lns.Story[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const generalStyles = generalStyle();

  const { currentLnsUser } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  const { storyPools } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  const storyPool = storyPools.find(sp => sp.guid === guid);
  useEffect(() => {
    fetchStoryPoolStories(guid).then(({ data }) => {
      if (data) {
        setStoryPoolStories(data);
      }
    });
  }, [guid]);

  useEffect(() => {
    fetchUserStories(currentLnsUser.guid).then(({ data }) =>
      setMyStories(data.slice().reverse())
    );
  }, [currentLnsUser]);

  if (!storyPool) {
    navigate('/story-pools');
    dispatch(
      toggleNotification(
        i18n.t('notifications.storyPools.storyFetchError'),
        'error'
      )
    );
    return <></>;
  }

  const onToggleStory = (story: $Lns.Story) => {
    const existInPool = storyPoolStories.find(s => s.guid === story.guid);
    if (existInPool) {
      const updatedStoryPoolStories = storyPoolStories.filter(
        s => s.guid !== story.guid
      );
      setStoryPoolStories(updatedStoryPoolStories);
    } else {
      const updatedStoryPoolStories = [story, ...storyPoolStories];
      setStoryPoolStories(updatedStoryPoolStories);
    }
  };

  const onHandleRowClick = (rowItem: { guid: { value: string } }) => {
    const story = myStories.find(s => s.guid === rowItem.guid.value);
    if (!story) return;
    onToggleStory(story);
  };

  const onCancel = () => {
    navigate('/story-pools');
  };

  const onSave = () => {
    const data = {
      pool: storyPool.guid,
      stories: storyPoolStories.map(s => {
        return {
          story: s.guid
        };
      })
    };
    updateStoryPoolStories(data).then(() => {
      dispatch(
        toggleNotification(
          i18n.t('notifications.storyPools.createSuccess'),
          'success'
        )
      );
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleBarWrapper>
          <Grid item xs={6}>
            <TitleWrapper title={storyPool.name} subText="Name" />
          </Grid>
          <Grid item xs={6}>
            <BtnWrapper>
              <Button color="default" variant="contained" onClick={onCancel}>
                {i18n.t('button.cancel')}
              </Button>
              <Button color="primary" variant="contained" onClick={onSave}>
                {i18n.t('button.save')}
              </Button>
            </BtnWrapper>
          </Grid>
        </TitleBarWrapper>
      </Grid>
      <Grid item xs={6}>
        <TabContext value="stories">
          <Tabs value="stories" scrollButtons="auto" variant="scrollable">
            <Tab
              label={i18n.t('pages.storyPools.helperText.selectedStories')}
              value="stories"
            />
          </Tabs>
          <TabPanel value="stories" className={generalStyles.paddingTopOnly}>
            <StoriesTable
              stories={storyPoolStories}
              onClick={onHandleRowClick}
            />
          </TabPanel>
        </TabContext>
      </Grid>

      <Grid item xs={6}>
        <StoryPoolTabs
          storyPools={storyPools}
          onToggleStory={onToggleStory}
          highlightedStories={storyPoolStories}
          approvedStories={false}
        />
      </Grid>
    </Grid>
  );
};

export default Edit;

import { ImageOutlined } from '@material-ui/icons';
import React, { FunctionComponent, useReducer, useState } from 'react';
import Accordion from 'components/Accordion';
import alphaOmegaAccordion from 'styles/AlphaOmega/alphaOmegaAccordion';
import logoMark from 'images/AlphaOmega/ao-logomark.svg';

import digitalIcon from 'images/AlphaOmega/Digital/digital.svg';
import facebook from 'images/AlphaOmega/Digital/facebook.svg';
import instagram from 'images/AlphaOmega/Digital/instagram.svg';
import linkedin from 'images/AlphaOmega/Digital/linkedin.svg';
import snapchat from 'images/AlphaOmega/Digital/snapchat.svg';
import tiktok from 'images/AlphaOmega/Digital/tiktok.svg';
import x from 'images/AlphaOmega/Digital/x.svg';

import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { runPrompts } from 'actions/alphaOmega';
import type { Prompts } from 'interfaces/AlphaOmega';
import AoConfirmationDialog from './AoConfirmationDialog';

interface AOAccordionI {
  id?: number | string;
  expandedId?: string | number;
  textPrompt: string;
  onChange: (id: number | string, expanded: boolean) => void;
  onSubmit: (text: string) => void;
}

const AoAccordion: FunctionComponent<AOAccordionI> = ({
  id,
  expandedId,
  textPrompt,
  onChange,
  onSubmit
}: AOAccordionI) => {
  const classes = alphaOmegaAccordion();
  const [openDialog, setDialog] = useState(false);
  const [dialogTextPrompt, setDialogTextPrompt] = useState('');

  // TODO: add i18n support
  const language = [
    { icon: '', text: 'English' },
    { icon: '', text: 'French' },
    { icon: '', text: 'German' },
    { icon: '', text: 'Hindi' },
    { icon: '', text: 'Arabic' }
  ];

  const medium = [
    { icon: '', text: 'Medium' },
    { icon: '', text: 'News Report' },
    { icon: '', text: 'Bulletin' },
    { icon: '', text: 'Briefing' },
    { icon: '', text: 'Feature Story' },
    { icon: '', text: 'Human-Interest Story' },
    { icon: '', text: 'Photo-Based News Report' },
    { icon: '', text: 'Infographic Bulletin' }
  ];

  const digital = [
    { icon: digitalIcon, text: 'Digital' },
    { icon: tiktok, text: 'TikTok' },
    { icon: x, text: 'X' },
    { icon: facebook, text: 'Facebook' },
    { icon: instagram, text: 'Instagram' },
    { icon: linkedin, text: 'LinkedIn' },
    { icon: snapchat, text: 'Snapchat' },
    { icon: '', text: 'Ticker' }
  ];

  const reducer = (
    state: Prompts,
    action: { type: string; payload: string }
  ) => {
    if (!action.type) return state;
    return {
      ...state,
      [action.type]: action.payload
    };
  };

  const [promptsState, dispatch] = useReducer(reducer, {
    language: language[0].text,
    medium: medium[0].text,
    digital: digital[0].text
  });

  async function generate(input: string) {
    const res = await runPrompts({ input, ...promptsState });
    setDialogTextPrompt(res.message);
    setDialog(true);
  }

  const headerComponent = (
    <div className={classes.header}>
      <img src={logoMark} width="70px" alt="AO Logo" />
      <div>
        <div className={classes.title}>Version Generation</div>
        <div className={classes.subtitle}>
          Choose prompts from the dropdown menus below.
        </div>
      </div>
    </div>
  );

  const selectIconComponent = (source: string) => {
    return source ? <img src={source} width="24px" alt="" /> : undefined;
  };

  return (
    <Accordion
      id={id}
      className={classes.aoAccordion}
      icon={<ImageOutlined />}
      headerComponent={headerComponent}
      expandedId={expandedId}
      onChange={onChange}
    >
      <Box className={classes.body}>
        <div className={classes.separator} />
        <Typography className={classes.bodyHeader}>Language</Typography>
        <FormControl variant="outlined" fullWidth>
          <Select
            classes={{ select: classes.select }}
            fullWidth
            name="language"
            displayEmpty
            placeholder="Language"
            value={promptsState.language}
            onChange={({ target }) =>
              dispatch({
                type: 'language',
                payload: target.value as string
              })
            }
          >
            {language.map(item => (
              <MenuItem value={item.text} key={item.text}>
                {selectIconComponent(item.icon)}
                {item.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={classes.separator} />
        <Typography className={classes.bodyHeader}>Prompts</Typography>
        <FormControl variant="outlined" fullWidth>
          <Select
            classes={{ select: classes.select }}
            fullWidth
            name="medium"
            displayEmpty
            value={promptsState.medium}
            onChange={({ target }) =>
              dispatch({ type: 'medium', payload: target.value as string })
            }
          >
            {medium.map(item => (
              <MenuItem
                className={classes.menuItem}
                value={item.text}
                key={item.text}
              >
                {selectIconComponent(item.icon)}
                {item.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <Select
            classes={{ select: classes.select }}
            fullWidth
            name="digital"
            displayEmpty
            value={promptsState.digital}
            onChange={({ target }) =>
              dispatch({
                type: 'digital',
                payload: target.value as string
              })
            }
          >
            {digital.map(item => (
              <MenuItem
                className={classes.menuItem}
                value={item.text}
                key={item.text}
              >
                {selectIconComponent(item.icon)}
                {item.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={classes.separator} />
        <Button
          color="primary"
          variant="contained"
          onClick={() => generate(textPrompt)}
        >
          Run Prompts
        </Button>
      </Box>
      {openDialog && (
        <AoConfirmationDialog
          text={dialogTextPrompt}
          prompts={promptsState}
          regenerate={generate}
          onClose={() => setDialog(false)}
          onSubmit={onSubmit}
        />
      )}
    </Accordion>
  );
};

export default AoAccordion;

AoAccordion.defaultProps = {
  id: 'alphaOmega',
  expandedId: undefined
};

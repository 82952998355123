import { FunctionComponent } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { EditOutlined, FindInPage, List } from '@material-ui/icons';
import i18n from 'i18n';
import BooleanIcon from 'components/BooleanIcon';
import {
  ProtectedIconButton,
  Resource,
  Action
} from 'components/Authorization';
import TableBuilder from 'components/Table';

interface ListRundownsI {
  rundowns: $Lns.Rundown[];
  onEdit: (rundown: $Lns.Rundown) => void;
  onEditStories: (rundown: $Lns.Rundown) => void;
  onShow: (rundown: $Lns.Rundown) => void;
  onRowClick: (guid: string) => void;
}

const ListRundowns: FunctionComponent<ListRundownsI> = ({
  rundowns,
  onEdit,
  onEditStories,
  onShow,
  onRowClick
}: ListRundownsI) => {
  const rows = [
    i18n.t('pages.rundowns.tableRow.name'),
    i18n.t('pages.rundowns.tableRow.playoutDateTime'),
    i18n.t('pages.rundowns.tableRow.totalDuration'),
    i18n.t('Number of cameras'),
    i18n.t('pages.rundowns.tableRow.isPublished'),
    '' // Actions
  ];

  const cells = rundowns.map(rundown => ({
    fields: {
      id: { value: rundown.guid, hidden: true },
      name: { value: rundown.name.toUpperCase() },
      playoutDateTime: { value: rundown.playoutDateTime.toUpperCase() },
      totalDuration: { value: rundown.totalDurationSec },
      numberOfCamera: { value: 1 }, // TODO: This was hardcoded to be set to 1, are we sticking with this?
      isPublished: { value: <BooleanIcon value={rundown.isPublished} /> },
      actions: {
        value: () => {
          if (!rundown) return <></>;
          return (
            <Box display="flex">
              <Tooltip
                title={i18n.t('pages.rundowns.tooltip.view').toString()}
                aria-label="view"
              >
                <ProtectedIconButton
                  size="small"
                  onClick={() => onShow(rundown)}
                  lnsResource={Resource.RUNDOWN}
                  lnsAction={Action.READ}
                >
                  <FindInPage color="secondary" />
                </ProtectedIconButton>
              </Tooltip>

              <Tooltip
                title={i18n.t('pages.rundowns.tooltip.stories').toString()}
                aria-label="view"
              >
                <ProtectedIconButton
                  size="small"
                  data-test-id="edit"
                  onClick={() => onEditStories(rundown)}
                  lnsResource={Resource.RUNDOWN}
                  lnsAction={Action.EDIT}
                >
                  <List color="secondary" />
                </ProtectedIconButton>
              </Tooltip>

              {!rundown.isPublished && (
                <Tooltip
                  title={i18n.t('pages.rundowns.tooltip.edit').toString()}
                  aria-label="add"
                >
                  <ProtectedIconButton
                    size="small"
                    onClick={() => onEdit(rundown)}
                    lnsResource={Resource.RUNDOWN}
                    lnsAction={Action.EDIT}
                  >
                    <EditOutlined color="secondary" />
                  </ProtectedIconButton>
                </Tooltip>
              )}
            </Box>
          );
        }
      }
    }
  }));

  return (
    <TableBuilder
      rows={rows}
      cells={cells}
      isDraggable
      onRowClick={(params: { id: string }) => onRowClick(params.id.toString())}
    />
  );
};

export default ListRundowns;

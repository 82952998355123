import { Box, Tab, Tabs, Grid } from '@material-ui/core';
import { ChangeEvent, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { StoryFormData } from 'pages/stories/interface';
import { AssetDynamic } from 'pages/stories/interfaces/assetDynamic';
import EmptyState from 'components/EmptyState';
import DetailedFields from 'pages/stories/components/Form/DetailedFields';
import TabPanel from 'components/TabPanel';
import StoryScriptForm from 'pages/stories/components/Form/StoryScript';
import Editor from 'pages/stories/components/Form/Editor';
import i18n from 'i18n';
import HistoryTab from './History';

interface StoryFormTabsI {
  tabValue: number;
  formData: StoryFormData;
  storyLock: boolean;
  calculatedDuration: number;
  dynamicAssets: AssetDynamic[];
  onChangeField: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeType: (e: ChangeEvent<{ name?: string; value: unknown }>) => void;
  onEditorChange: (text: string, field: string) => void;
  onAddDialogueAsset: (dialogue: AssetDynamic) => void;
  onUpdateDynamicAssets: (assetsNew: AssetDynamic[]) => void;
  onChangeTabValue: (val: number) => void;
  story: string;
}

const StoryFormTabs: FunctionComponent<StoryFormTabsI> = ({
  formData,
  tabValue,
  storyLock,
  calculatedDuration,
  dynamicAssets,
  onChangeField,
  onChangeType,
  onEditorChange,
  onAddDialogueAsset,
  onUpdateDynamicAssets,
  onChangeTabValue,

  story
}: StoryFormTabsI) => {
  const { storyTypes, storyGenres, storyPools, locations } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  const handleChange = (
    event: ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => {
    onChangeTabValue(newValue);
  };

  return (
    <Box display="grid" gridAutoFlow="row" width="100%" gridGap={10}>
      <Tabs
        value={tabValue}
        variant="scrollable"
        scrollButtons="auto"
        onChange={handleChange}
        aria-label="story form tabs"
      >
        <Tab
          data-test-id="print-format-tab"
          label={i18n.t('pages.stories.tabLabel.printFormat')}
        />
        <Tab label={i18n.t('pages.stories.tabLabel.info')} />
        <Tab
          data-test-id="script-tab"
          label={i18n.t('pages.stories.tabLabel.script')}
        />

        <Tab
          data-test-id="attachments-tab"
          label={i18n.t('pages.stories.tabLabel.attachments')}
        />
        <Tab
          data-test-id="history-tab"
          label={i18n.t('pages.stories.tabLabel.history')}
        />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <Grid item xs={12}>
          <Editor
            text={formData.text}
            onTextChange={onEditorChange}
            field="text"
          />
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={1} data-test-id="form-tab">
        <DetailedFields
          storyLock={storyLock}
          calculatedDuration={calculatedDuration}
          formData={formData}
          locations={locations}
          storyTypes={storyTypes}
          storyPools={storyPools}
          storyGenres={storyGenres}
          onChangeField={onChangeField}
          onChangeType={onChangeType}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2} data-test-id="script-tab">
        <StoryScriptForm
          calculatedDuration={calculatedDuration}
          storyLock={storyLock}
          formData={formData}
          dynamicAssets={dynamicAssets}
          onAddDialogueAsset={onAddDialogueAsset}
          onUpdateDynamicAssets={onUpdateDynamicAssets}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <EmptyState
          title={i18n.t('pages.stories.tabLabel.attachments')}
          subTitle="No content yet"
        />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <HistoryTab story={story} />
      </TabPanel>
    </Box>
  );
};

export default StoryFormTabs;

import { FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import CustomTextField from 'components/CustomTextField';
import i18n from 'i18n';
import { ChangeEvent, FunctionComponent } from 'react';
import { snakeToCamel } from 'utils/string';
import { generalStyle } from 'styles/generalStyle';
import shortid from 'shortid';
import { FormData } from '../formData';

interface SingleFormFieldsI {
  formData: FormData;
  getCategory: (guid: string) => $Lns.StoryCategory | undefined;
  onChangeField: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeType: (e: ChangeEvent<{ name?: string; value: unknown }>) => void;
  onChangeDate: (date: MaterialUiPickersDate) => void;
  storyCategories: $Lns.StoryCategory[];
}

const SingleFormFields: FunctionComponent<SingleFormFieldsI> = ({
  formData,
  getCategory,
  onChangeField,
  onChangeType,
  onChangeDate,
  storyCategories
}: SingleFormFieldsI) => {
  const generalStyles = generalStyle();
  return (
    <>
      <Grid item container>
        <CustomTextField
          onChange={onChangeField}
          value={formData.name}
          label={i18n.t('pages.rundowns.formLabel.name')}
          name="name"
          error={!!formData?.errors.name}
          helperText={formData.errors.name || i18n.t('helperText.required')}
          className={generalStyles.textUppercase}
        />
      </Grid>

      <Grid item>
        <FormControl margin="normal" variant="outlined" fullWidth size="small">
          <Select
            labelId="category"
            id="category"
            name="category"
            placeholder={i18n.t('pages.rundowns.placeHolder.category')}
            required
            value={formData.category}
            onChange={onChangeType}
            displayEmpty
            renderValue={(value: unknown) => {
              const key = getCategory(value as string)?.key;
              return i18n.t(
                key
                  ? `pages.rundowns.formLabel.categoryType.${snakeToCamel(key)}`
                  : 'pages.rundowns.formLabel.category'
              );
            }}
          >
            {storyCategories.map(storyCategory => (
              <MenuItem key={shortid()} value={storyCategory.guid}>
                {i18n.t(
                  `pages.rundowns.formLabel.categoryType.${snakeToCamel(
                    storyCategory.key
                  )}`
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item>
        <DateTimePicker
          margin="normal"
          name="playoutDateTime"
          fullWidth
          ampm={false}
          emptyLabel={i18n.t('pages.rundowns.formLabel.playoutDateTime')}
          minDate={new Date()}
          size="small"
          id="playoutDateTime"
          placeholder={i18n.t('pages.rundowns.formLabel.playoutDateTime')}
          inputVariant="outlined"
          error={!!formData?.errors?.playoutDateTime}
          helperText={formData.errors.playoutDateTime}
          value={formData.playoutDateTime}
          onChange={date => onChangeDate(date)}
        />
      </Grid>
    </>
  );
};

export default SingleFormFields;

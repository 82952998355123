import { FunctionComponent, useEffect, useState, ChangeEvent } from 'react';
import { Dialog, Grid, IconButton, Paper } from '@material-ui/core';
import { Search, SettingsInputSvideo } from '@material-ui/icons';
import i18n from 'i18n';
import { useDispatch } from 'react-redux';
import toggleNotification from 'actions/notifications';
import EmptyState from 'components/EmptyState';
import Loading from 'components/Loading';
import List from 'pages/administration/peripheral-settings/peripherals/components/List';
import ItemDetail from 'pages/administration/peripheral-settings/peripherals/components/ItemDetail';
import Form from 'pages/administration/peripheral-settings/peripherals/components/Form';
import TemplatePicker from 'pages/administration/peripheral-settings/peripherals/components/TemplatePicker';
import { FormData } from 'pages/administration/peripheral-settings/peripherals/formData';
import {
  fetchPeripherals,
  updatePeripheral,
  createPeripheral,
  deletePeripheral,
  fetchPeripheralTypes,
  fetchProtocols
} from 'pages/administration/peripheral-settings/actions';
import storiesStyles from 'pages/stories/styles';
import TitleWrapper from 'components/TitleWrapper';
import TitleBarWrapper from 'components/TitleBarWrapper';
import BtnWrapper from 'components/BtnWrapper';
import { ProtectedButton, Resource, Action } from 'components/Authorization';
import BasicTextField from 'components/BasicTextField';

const Peripherals: FunctionComponent = () => {
  // Ready the Hooks
  const dispatch = useDispatch();
  const classes = storiesStyles();

  // Peripheral Records
  const [peripherals, setPeripherals] = useState<$Lns.Peripheral[]>();
  const [peripheralsTemp, setPeripheralsTemp] = useState<$Lns.Peripheral[]>();

  const [peripheralTypes, setPeripheralTypes] =
    useState<$Lns.PeripheralType[]>();

  const [protocols, setProtocols] = useState<$Lns.Protocol[]>();

  // Page Level Fetch and Set Functions
  const fetchSetPeripherals = () => {
    fetchPeripherals().then(({ data }) => {
      setPeripherals(data);
      setPeripheralsTemp(data);
    });
  };

  const refreshPage = () => {
    fetchSetPeripherals();
    if (addWithTemplate) setAddWithTemplate(false);
    setEditPeripheral(undefined);
  };
  // 1st Fetch and Set of this Page
  useEffect(() => {
    fetchSetPeripherals();
    fetchPeripheralTypes().then(({ data }) => setPeripheralTypes(data));
    fetchProtocols().then(({ data }) => setProtocols(data));
  }, []);

  // Add and Edit Peripheral Dialog States and Functions
  const [editPeripheral, setEditPeripheral] = useState<$Lns.Peripheral>();
  const [addWithTemplate, setAddWithTemplate] = useState<boolean>(false);

  const onEdit = (peripheral: $Lns.Peripheral) => {
    setEditPeripheral(peripheral);
  };

  const onEditCancel = () => {
    setEditPeripheral(undefined);
  };

  const onAdd = () => {
    setEditPeripheral({
      type: '',
      name: '',
      protocol: '',
      config: {}
    } as $Lns.Peripheral);
  };

  const onSave = (formData: FormData) => {
    if (formData.guid) {
      updatePeripheral(formData).then(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.peripherals.createSuccess'),
            'success'
          )
        );
        refreshPage();
      });
    } else {
      createPeripheral(formData).then(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.peripherals.createSuccess'),
            'success'
          )
        );
        refreshPage();
      });
    }
  };

  // Template Picking Dialog Functions
  const onAddWithTemplateCancel = () => {
    setAddWithTemplate(false);
  };

  const onAddWithTemplate = () => {
    setAddWithTemplate(true);
  };

  const onPickedTemplate = (template: $Lns.PeripheralTemplate) => {
    setEditPeripheral({
      type: template.type,
      name: template.name,
      protocol: template.protocol,
      config: template.config
    } as $Lns.Peripheral);
  };

  // Delete from List
  const onDelete = (peripheral: $Lns.Peripheral) => {
    deletePeripheral(peripheral)
      .then(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.peripherals.deleteSuccess'),
            'success'
          )
        );
        fetchSetPeripherals();
        setEditPeripheral(undefined);
      })
      .catch(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.peripherals.deleteError'),
            'success'
          )
        );
      });
  };

  // Quick View States and Functions
  const [showPeripheral, setShowPeripheral] = useState<$Lns.Peripheral>();

  const onShow = (peripheral: $Lns.Peripheral) => {
    setShowPeripheral(peripheral);
  };

  const onClose = () => setShowPeripheral(undefined);

  // Search Field Functions
  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const newPeripherals = peripherals?.filter(story => {
      return story.guid.includes(search) || story.name.includes(search);
    });
    setPeripheralsTemp(newPeripherals);
  };

  // THE DOM
  return (
    <>
      <Grid container spacing={2}>
        <TitleBarWrapper>
          <Grid item xs={6}>
            <TitleWrapper
              title={i18n.t(
                'links.sidebarNestedItems.administration.peripherals'
              )}
              subText={i18n.t('pages.stories.formLabel.title')}
              icon={<SettingsInputSvideo color="primary" />}
            />
          </Grid>
          <Grid item xs={6}>
            <BtnWrapper>
              <ProtectedButton
                color="secondary"
                variant="contained"
                onClick={onAddWithTemplate}
                lnsResource={Resource.PERIPHERAL}
                lnsAction={Action.CREATE}
              >
                {i18n.t(
                  'pages.administration.peripherals.button.addWithTemplate'
                )}
              </ProtectedButton>
              <ProtectedButton
                color="secondary"
                variant="contained"
                onClick={onAdd}
                lnsResource={Resource.PERIPHERAL}
                lnsAction={Action.CREATE}
              >
                {i18n.t('pages.administration.peripherals.button.add')}
              </ProtectedButton>
            </BtnWrapper>
          </Grid>
        </TitleBarWrapper>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <BasicTextField
              className={classes.input}
              placeholder={i18n.t('placeholders.search')}
              onChange={onSearchFieldChange}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
            {peripherals && peripherals.length === 0 && (
              <EmptyState
                title="no data"
                subTitle="add some data to proceed!"
              />
            )}
            {peripherals &&
              peripherals.length > 0 &&
              peripheralTypes &&
              peripheralTypes.length > 0 &&
              protocols &&
              protocols.length > 0 && (
                <List
                  peripherals={peripheralsTemp || []}
                  peripheralTypes={peripheralTypes}
                  protocols={protocols}
                  onShow={onShow}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              )}
            {!peripherals && <Loading />}
          </Grid>
        </Grid>
      </Grid>
      {editPeripheral && (
        <Dialog onClose={onEditCancel} aria-labelledby="edit-peripheral" open>
          <Form
            peripheral={editPeripheral}
            types={peripheralTypes || []}
            protocols={protocols || []}
            onSave={onSave}
            onCancel={onEditCancel}
          />
        </Dialog>
      )}
      {addWithTemplate && (
        <Dialog
          onClose={onAddWithTemplateCancel}
          aria-labelledby="edit-peripheral"
          open
        >
          <TemplatePicker
            onSave={onPickedTemplate}
            onCancel={onAddWithTemplateCancel}
          />
        </Dialog>
      )}
      {showPeripheral && (
        <ItemDetail
          peripheralItem={showPeripheral}
          protocols={protocols || []}
          types={peripheralTypes || []}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default Peripherals;

import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import StoryPoolList from 'pages/story-pools/components/List';
import { fetchStoryPools } from 'actions/storyPools';
import toggleNotification from 'actions/notifications';
import { useDispatch } from 'react-redux';
import i18n from 'i18n';
import TitleWrapper from 'components/TitleWrapper';
import { Resource, Action, useWithPermissions } from 'components/Authorization';
import ContentBox from 'components/ContentBox';
import ContentBoxItem from 'components/ContentBoxItem';
import Search from 'components/Search';

const NewsPoolContainer: FunctionComponent = () => {
  const navigate = useNavigate();
  const [storyPools, setStoryPools] = useState<$Lns.StoryPool[]>([]);
  const [storyPoolsOrig, setStoryPoolsOrig] = useState<$Lns.StoryPool[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSetStoryPools = () => {
      fetchStoryPools()
        .then(({ data }) => {
          setStoryPools(data);
          setStoryPoolsOrig(data);
        })
        .catch(() =>
          dispatch(
            toggleNotification('Failed to load publish folders', 'error')
          )
        );
    };

    fetchSetStoryPools();
  }, [dispatch]);

  const onEdit = (storyPool: $Lns.StoryPool) => {
    navigate(`/story-pools/${storyPool.guid}/edit`);
  };

  const onRowClick = useWithPermissions(
    (storyPool: { guid: { value: string }; name: { value: string } }) => {
      const {
        guid: { value: guidValue }
      } = storyPool;
      navigate(`/story-pools/${guidValue}`);
    },
    Resource.STORY_POOL,
    Action.READ
  );

  return (
    <ContentBox>
      <ContentBoxItem>
        <TitleWrapper title={i18n.t('links.sidebar.storyPools')} />
        <Search
          fields={storyPoolsOrig}
          searchKeys={['guid', 'name']}
          filterFields={setStoryPools}
        />
      </ContentBoxItem>

      <StoryPoolList
        storyPools={storyPools}
        onEdit={onEdit}
        onRowClick={onRowClick}
      />
    </ContentBox>
  );
};

export default NewsPoolContainer;

import { Box, Typography } from '@material-ui/core';
import { FunctionComponent, ReactNode } from 'react';
import colors from 'styles/colors';
import titleStyles from 'styles/titleWrapper';

interface TitleWrapperI {
  title: string;
  titleColor?: string;
  subText?: string;

  subtextColor?: string;
  icon?: ReactNode;
  textIcon?: ReactNode;
}

const TitleWrapper: FunctionComponent<TitleWrapperI> = ({
  title,
  titleColor,
  subText,
  subtextColor,
  icon,
  textIcon
}: TitleWrapperI) => {
  const classes = titleStyles();

  return (
    <Box className={classes.titleWrapper}>
      {icon}
      <Box className={classes.textWrapper}>
        <Typography
          className={classes.titleBar}
          variant="h1"
          style={{ color: titleColor }}
        >
          <span>{title}</span>
          {textIcon}
        </Typography>
        <Typography variant="subtitle1" style={{ color: subtextColor }}>
          {subText}
        </Typography>
      </Box>
    </Box>
  );
};

export default TitleWrapper;

TitleWrapper.defaultProps = {
  titleColor: colors.gray[900],
  subtextColor: colors.gray[600],
  subText: undefined,
  icon: undefined,
  textIcon: undefined
};

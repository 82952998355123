import { Box } from '@material-ui/core';
import {
  CloudUploadOutlined,
  ImageOutlined,
  PlayCircleOutlineOutlined,
  VolumeUpOutlined
} from '@material-ui/icons';
import { FunctionComponent, useState, useEffect } from 'react';
import { fetchPeripherals, fetchPeripheralTypes } from 'actions/peripheral';
import { AssetDynamic } from 'pages/stories/interfaces/assetDynamic';

import AssetManager from 'pages/stories/components/Asset/Manager';
import EmptyState from 'components/EmptyState';
import i18n from 'i18n';

import AssetUpload from 'pages/stories/components/Asset/Upload';
import Accordion from 'components/Accordion';
import AoAccordion from 'components/AlphaOmega/AoAccordion';

interface StoryBrowseAccordionsI {
  textPrompt: string;
  storyLock: boolean;
  onUpdateAssets: (asset: AssetDynamic) => void;
  onAoSubmit: (textPrompt: string) => void;
}

const StoryBrowseAccordions: FunctionComponent<StoryBrowseAccordionsI> = ({
  textPrompt,
  storyLock,
  onUpdateAssets,
  onAoSubmit
}: StoryBrowseAccordionsI) => {
  const [expanded, setExpanded] = useState<string | number | undefined>(
    'alphaOmega'
  );

  const [peripherals, setPeripherals] = useState<$Lns.Peripheral[]>([]);
  const [selectedPerif, setSelectedPeripheral] = useState<$Lns.Peripheral>();

  const handleChange = (id: number | string, isExpanded: boolean) => {
    setExpanded(isExpanded ? id : undefined);
  };

  useEffect(() => {
    fetchPeripherals().then(({ data: pData, code: pCode }) => {
      if (pCode === 200 && pData.length > 0) {
        // filter for MAM
        fetchPeripheralTypes().then(({ data: tData, code: tCode }) => {
          if (tCode === 200 && tData.length > 0) {
            const mamType = tData.find(pin => pin.key.includes('mam'));

            const filteredData = pData.filter(peripheral => {
              return peripheral.type === mamType?.guid;
            });

            if (filteredData) {
              setPeripherals(filteredData);
              const selectedObj = filteredData.find(pin =>
                pin.name.includes('Metus')
              );
              setSelectedPeripheral(selectedObj);
            }
          }
        });
      }
    });
  }, []);

  const onSelectPeripheral = (pin: $Lns.Peripheral) => {
    setSelectedPeripheral(pin);
  };

  return (
    <Box display="grid" gridAutoFlow="row" width="100%" gridGap={16}>
      <AoAccordion
        id="alphaOmega"
        expandedId={expanded}
        textPrompt={textPrompt}
        onChange={handleChange}
        onSubmit={onAoSubmit}
      />
      <Accordion
        icon={<ImageOutlined />}
        title={i18n.t('pages.stories.tabLabel.images')}
        expandedId={expanded}
        onChange={handleChange}
      >
        <AssetManager
          storyLock={storyLock}
          protocol={selectedPerif}
          assetType="Image"
          onUpdateAssets={onUpdateAssets}
          peripherals={peripherals}
          onSelectPeripheral={onSelectPeripheral}
        />
        {peripherals === undefined && (
          <EmptyState
            title={i18n.t('pages.stories.tabLabel.images')}
            subTitle="No content yet"
          />
        )}
      </Accordion>
      <Accordion
        title={i18n.t('pages.stories.tabLabel.videos')}
        icon={<PlayCircleOutlineOutlined />}
        expandedId={expanded}
        onChange={handleChange}
      >
        <AssetManager
          storyLock={storyLock}
          protocol={selectedPerif}
          assetType="Video"
          onUpdateAssets={onUpdateAssets}
          peripherals={peripherals}
          onSelectPeripheral={onSelectPeripheral}
        />
        {peripherals === undefined && (
          <EmptyState
            title={i18n.t('pages.stories.tabLabel.videos')}
            subTitle="No content yet"
          />
        )}
      </Accordion>
      <Accordion
        title={i18n.t('pages.stories.tabLabel.audio')}
        icon={<VolumeUpOutlined />}
        expandedId={expanded}
        onChange={handleChange}
      >
        <AssetManager
          storyLock={storyLock}
          protocol={selectedPerif}
          assetType="Audio"
          onUpdateAssets={onUpdateAssets}
          peripherals={peripherals}
          onSelectPeripheral={onSelectPeripheral}
        />
        {peripherals === undefined && (
          <EmptyState
            title={i18n.t('pages.stories.tabLabel.audio')}
            subTitle="No content yet"
          />
        )}
      </Accordion>
      <Accordion
        title={i18n.t('pages.stories.tabLabel.upload')}
        icon={<CloudUploadOutlined />}
        expandedId={expanded}
        onChange={handleChange}
      >
        <AssetUpload
          storyLock={storyLock}
          protocol={selectedPerif}
          peripheral={peripherals}
          onSelectPeripheral={onSelectPeripheral}
        />
        {peripherals === undefined && (
          <EmptyState
            title={i18n.t('pages.stories.tabLabel.images')}
            subTitle="No content yet"
          />
        )}
      </Accordion>
    </Box>
  );
};

export default StoryBrowseAccordions;

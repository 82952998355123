import { FunctionComponent, useEffect, useState, ChangeEvent } from 'react';
import { Dialog, Grid, IconButton, Paper } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import i18n from 'i18n';
import {
  createUserGroup,
  fetchAllUserGroups,
  updateUserGroupByGuid,
  removeUserGroupByGuid
} from 'pages/administration/security/groups/actions';
import List from 'pages/administration/security/groups/components/List';
import Form from 'pages/administration/security/groups/components/Form';
import { FormData } from 'pages/administration/security/groups/formData';
import toggleNotification from 'actions/notifications';
import { Search } from '@material-ui/icons';
import storiesStyles from 'pages/stories/styles';
import TitleWrapper from 'components/TitleWrapper';
import TitleBarWrapper from 'components/TitleBarWrapper';
import BtnWrapper from 'components/BtnWrapper';
import SecurityIcon from '@material-ui/icons/Security';
import { ProtectedButton, Resource, Action } from 'components/Authorization';
import BasicTextField from 'components/BasicTextField';

const UserGroupsPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = storiesStyles();
  const [userGroups, setUserGroups] = useState<$Lns.UserGroup[]>([]);
  const [userGroupsOrig, setUserGroupsOrig] = useState<$Lns.UserGroup[]>([]);

  const handleApiError = () => {
    dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
  };

  const [editUserGroup, setEditUserGroup] = useState<FormData>();

  const fetchSetUserGroups = () => {
    fetchAllUserGroups().then(({ data }) => {
      setUserGroups(data);
      setUserGroupsOrig(data);
    });
  };
  useEffect(() => fetchSetUserGroups(), []);

  const onAdd = () => {
    setEditUserGroup({ name: '', errors: { name: '' } });
  };

  const onEditCancel = () => {
    setEditUserGroup(undefined);
  };

  const onSave = (formData: FormData) => {
    if (formData.guid) {
      updateUserGroupByGuid(formData)
        .then(() => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.userGroups.saveSuccess'),
              'success'
            )
          );
          fetchSetUserGroups();
          setEditUserGroup(undefined);
        })
        .catch(handleApiError);
    } else {
      createUserGroup(formData)
        .then(({ data }) => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.userGroups.createSuccess'),
              'success'
            )
          );
          onGotoGroupItem(data);
          setEditUserGroup(undefined);
        })
        .catch(handleApiError);
    }
  };

  const onRowClick = (rowItem: {
    guid: { value: string };
    name: { value: string };
  }) => {
    const {
      guid: { value: guidValue },
      name: { value: nameValue }
    } = rowItem;
    navigate(`/administration/security/user-groups/${guidValue}`, {
      state: { routeName: nameValue }
    });
  };

  const onGotoGroupItem = (rowItem: {
    guid: { value: string };
    name: { value: string };
  }) => {
    const { guid, name } = rowItem;
    navigate(`/administration/security/user-groups/${guid}`, {
      state: { routeName: name }
    });
  };

  const onDelete = (userGroup: $Lns.UserGroup) => {
    removeUserGroupByGuid(userGroup)
      .then(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.userGroups.removeSuccess'),
            'success'
          )
        );
        fetchSetUserGroups();
      })
      .catch(handleApiError);
  };

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const newSetUserGroups = userGroupsOrig.filter(item => {
      return item.guid.includes(search) || item.name.includes(search);
    });
    setUserGroups(newSetUserGroups);
  };

  return (
    <>
      <Grid container spacing={2}>
        <TitleBarWrapper>
          <Grid item xs={6}>
            <TitleWrapper
              title={i18n.t('links.sidebarNestedItems.administration.security')}
              subText={i18n.t('pages.stories.formLabel.title')}
              icon={<SecurityIcon color="primary" />}
            />
          </Grid>
          <Grid item xs={6}>
            <BtnWrapper>
              <ProtectedButton
                lnsResource={Resource.SECURITY}
                lnsAction={Action.EDIT}
                color="secondary"
                variant="contained"
                onClick={onAdd}
              >
                {i18n.t('pages.administration.userGroups.button.add')}
              </ProtectedButton>
            </BtnWrapper>
          </Grid>
        </TitleBarWrapper>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <BasicTextField
              className={classes.input}
              placeholder={i18n.t('placeholders.search')}
              onChange={onSearchFieldChange}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <List
            userGroups={userGroups}
            onRowClick={onRowClick}
            onEdit={onGotoGroupItem}
            onDelete={onDelete}
          />
        </Grid>
      </Grid>

      {editUserGroup && (
        <Dialog
          onClose={onEditCancel}
          aria-labelledby="delete-usergroup-dialog"
          open
        >
          <Form
            onSave={onSave}
            onCancel={onEditCancel}
            userGroup={editUserGroup}
          />
        </Dialog>
      )}
    </>
  );
};

export default UserGroupsPage;

import React, { FunctionComponent, useState } from 'react';
import aoConfirmationDialogStyles from 'styles/AlphaOmega/alphaOmegaConfirmationDialog';
import { Box, Button, Dialog, Typography } from '@material-ui/core';
import Editor from 'pages/stories/components/Form/Editor';
import type { Prompts } from 'interfaces/AlphaOmega';
import BtnWrapper from 'components/BtnWrapper';
import { AutorenewOutlined } from '@material-ui/icons';

interface AOConfirmationDialogI {
  text: string;
  prompts: Prompts;
  regenerate: (input: string) => Promise<void>;
  onClose: () => void;
  onSubmit: (text: string) => void;
}

const AoConfirmationDialog: FunctionComponent<AOConfirmationDialogI> = ({
  text,
  prompts,
  regenerate,
  onClose,
  onSubmit
}: AOConfirmationDialogI) => {
  const classes = aoConfirmationDialogStyles();
  const [textState, setTextState] = useState(text);

  const onSubmitDialog = () => {
    onSubmit(textState);
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      aria-labelledby="simple-dialog-title"
      open
      onClose={onClose}
    >
      <Box className={classes.body}>
        <Typography>{`ΑΩ ${prompts.digital ? `${prompts.digital} w/ ` : ''} ${
          prompts.language
        } ${prompts.medium}`}</Typography>
        <Editor
          text={text}
          field="AOPrompt"
          onTextChange={newContent => setTextState(newContent)}
        />
        <Button
          className={classes.regenerate}
          onClick={() => regenerate(textState)}
        >
          <AutorenewOutlined />
          <Typography>Regenerate</Typography>
        </Button>
        <BtnWrapper>
          <Button color="secondary" variant="contained" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={onSubmitDialog}>
            Submit to Rundown/Publish
          </Button>
        </BtnWrapper>
      </Box>
    </Dialog>
  );
};

export default AoConfirmationDialog;

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import colors from './colors';

const mainLayoutStyles = makeStyles((theme: Theme) => {
  const drawerWidth = theme.spacing(37);

  return createStyles({
    sidebar: {
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    sidebarContent: {
      padding: `${theme.spacing(3)}px ${theme.spacing(2) - 2}px`,
      overflowX: 'hidden'
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(8) + 4
      }
    },
    drawerBg: {
      background: colors.gray[950]
    },
    title: {
      marginLeft: theme.spacing(0.5),
      transition: theme.transitions.create('gap', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen * 1.5
      }),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      gap: theme.spacing(2) + 2
    },
    titleOpen: {
      gap: theme.spacing(1)
    },
    titleLogo: {
      width: theme.spacing(4),
      height: theme.spacing(4)
    },
    titleText: {
      height: 27,
      width: 'fit-content'
    },
    titleIcon: {
      boxSizing: 'content-box',
      marginLeft: 'auto',
      padding: theme.spacing(1),
      borderRadius: '6px',
      cursor: 'pointer',
      '&:hover': {
        background: colors.gray[800]
      }
    },
    titleIconLocked: {
      background: colors.gray[800],
      '&:hover': {
        background: colors.gray[700]
      }
    },
    list: {
      paddingTop: 20,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.25)
    },
    version: {
      transition: theme.transitions.create(['height', 'padding', 'border'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      height: theme.spacing(6.5),
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      border: `1px solid ${colors.gray[800]}`,
      borderRadius: theme.spacing(1.5),
      color: colors.gray[400],
      fontSize: 14,
      display: 'flex',
      alignItems: 'center'
    },
    versionCollapse: {
      height: 0,
      borderColor: colors.gray[950],
      overflow: 'hidden'
    },
    accountMain: {
      transition: theme.transitions.create(['padding', 'background', 'gap'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen * 1.5
      }),
      marginTop: theme.spacing(2),
      borderRadius: theme.spacing(1.5),
      display: 'flex',
      gap: theme.spacing(3),
      alignItems: 'center'
    },
    accountExpanded: {
      padding: theme.spacing(1.5),
      background: colors.gray[900],
      border: colors.gray[800],
      gap: theme.spacing(1)
    },
    accountButton: {
      padding: 0
    },
    accountAvatar: {
      color: colors.gray[600],
      background: colors.gray[100]
    },
    accountDetails: {
      display: 'flex',
      flexDirection: 'column'
    },
    accountName: {
      color: colors.gray[50],
      fontSize: 14,
      fontWeight: 600
    },
    accountIconBox: {
      position: 'relative',
      right: theme.spacing(0.75),
      top: -theme.spacing(0.75),
      width: 32,
      height: 32,
      padding: theme.spacing(0.75),
      borderRadius: 6,
      '&:hover': {
        background: colors.gray[800]
      }
    },
    accountIconBoxOpen: {
      background: colors.gray[800],
      '&:hover': {
        background: colors.gray[700]
      }
    },
    accountIconBoxLabel: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    accountIcon: {
      width: 18,
      height: 18,
      margin: '-5px 0'
    },
    accountMenu: {
      marginBottom: theme.spacing(1),
      border: `1px solid ${colors.gray[800]}`,
      borderRadius: theme.spacing(1.5),
      color: colors.gray[300],
      fontWeight: 600,
      boxShadow: 'none',
      background: colors.gray[800],

      display: 'flex',
      flexDirection: 'column'
    },
    accountMenuSubmenu: {
      borderRadius: theme.spacing(1.5),
      background: colors.gray[950],
      marginTop: -theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.25)
    },
    accountMenuItem: {
      borderRadius: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      display: 'flex',
      gap: theme.spacing(1),
      '&:hover': {
        border: `1px solid ${colors.gray[800]}`,
        background: colors.gray[900]
      }
    }
  });
});

export default mainLayoutStyles;

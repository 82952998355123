import { makeStyles } from '@material-ui/core';
import colors from './colors';

const contentStyles = makeStyles({
  label: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    color: colors.gray[700]
  },
  textField: {
    padding: '10px 14px',
    marginTop: 6,
    marginBottom: 0,
    border: `1px solid ${colors.gray[300]}`,
    borderRadius: 8,
    background: colors.base.white
  }
});

export default contentStyles;

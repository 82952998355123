import type { ReactNode } from 'react';
import { Box } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import contentStyles from 'styles/sideContent';

interface SideContentI {
  children?: ReactNode | ReactNode[];
}

const SideContent: FunctionComponent<SideContentI> = ({
  children
}: SideContentI) => {
  const classes = contentStyles();

  return (
    <Box className={classes.sideContent}>
      {Array.isArray(children) ? children.map(item => item) : children}
    </Box>
  );
};

export default SideContent;

SideContent.defaultProps = {
  children: undefined
};

import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Grid, Button } from '@material-ui/core';
import i18n from 'i18n';
import toggleNotification from 'actions/notifications';
import Loading from 'components/Loading';
import EmptyState from 'components/EmptyState';
import List from 'pages/administration/peripheral-settings/templates/components/List';
import Form from 'pages/administration/peripheral-settings/peripherals/components/Form';
import ItemDetail from 'pages/administration/peripheral-settings/peripherals/components/ItemDetail';
import {
  fetchPeripheralTemplates,
  createPeripheralTemplate,
  updatePeripheralTemplate,
  deletePeripheralTemplate,
  fetchPeripheralTypes,
  fetchProtocols
} from 'pages/administration/peripheral-settings/actions';
import { FormData } from 'pages/administration/peripheral-settings/peripherals/formData';
import TitleWrapper from 'components/TitleWrapper';
import TitleBarWrapper from 'components/TitleBarWrapper';
import BtnWrapper from 'components/BtnWrapper';
import { SettingsInputSvideo } from '@material-ui/icons';

const PeripheralTemplates: FunctionComponent = () => {
  const dispatch = useDispatch();

  const handleApiError = () => {
    dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
  };

  const [peripheralTemplates, setPeripheralTemplates] =
    useState<$Lns.PeripheralTemplate[]>();

  const [peripheralTypes, setPeripheralTypes] =
    useState<$Lns.PeripheralType[]>();

  const [protocols, setProtocols] = useState<$Lns.Protocol[]>();

  const fetchSetTemplates = () => {
    fetchPeripheralTemplates().then(({ data }) => {
      setPeripheralTemplates(data);
    });
  };
  useEffect(() => {
    fetchSetTemplates();
    fetchPeripheralTypes().then(({ data }) => {
      setPeripheralTypes(data);
    });
    fetchProtocols().then(({ data }) => setProtocols(data));
  }, []);

  const [editTemplate, setEditTemplate] = useState<$Lns.PeripheralTemplate>();
  const [showTemplate, setShowTemplate] = useState<$Lns.PeripheralTemplate>();

  const onAdd = () => {
    setEditTemplate({
      type: '',
      name: '',
      protocol: '',
      config: {}
    } as $Lns.PeripheralTemplate);
  };

  const onEdit = (templateItem: $Lns.PeripheralTemplate) => {
    setEditTemplate(templateItem);
  };

  const onSave = (formData: FormData) => {
    if (formData.guid === undefined) {
      createPeripheralTemplate(formData)
        .then(() => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.peripheralTemplates.addSuccess'),
              'success'
            )
          );
          fetchSetTemplates();
        })
        .catch(handleApiError);
    } else {
      updatePeripheralTemplate(formData)
        .then(() => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.peripheralTemplates.updateSuccess'),
              'success'
            )
          );
          fetchSetTemplates();
        })
        .catch(handleApiError);
    }
    setEditTemplate(undefined);
  };

  const onEditCancel = () => setEditTemplate(undefined);

  const onDelete = (templateItem: $Lns.PeripheralTemplate) => {
    deletePeripheralTemplate(templateItem)
      .then(() => {
        const msg = i18n.t('pages.administration.peripheralTemplates.error');
        dispatch(toggleNotification(msg, 'error'));

        fetchSetTemplates();
        setEditTemplate(undefined);
      })
      .catch(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.peripheralTemplates.deleteSuccess'),
            'success'
          )
        );
      });
  };

  const onShow = (templateItem: $Lns.PeripheralTemplate) => {
    setShowTemplate(templateItem);
  };

  const onClose = () => setShowTemplate(undefined);

  return (
    <Grid container spacing={2}>
      <TitleBarWrapper>
        <Grid item xs={6}>
          <TitleWrapper
            title={i18n.t(
              'links.sidebarNestedItems.administration.peripheralTemplates'
            )}
            subText={i18n.t('pages.stories.formLabel.title')}
            icon={<SettingsInputSvideo color="primary" />}
          />
        </Grid>
        <Grid item xs={6}>
          <BtnWrapper>
            <Button color="secondary" variant="contained" onClick={onAdd}>
              {i18n.t('pages.administration.peripheralTemplates.button.add')}
            </Button>
          </BtnWrapper>
        </Grid>
      </TitleBarWrapper>
      <Grid item xs={12}>
        {!peripheralTemplates && <Loading />}
        {peripheralTemplates && peripheralTemplates.length === 0 && (
          <EmptyState
            title={i18n.t(
              'pages.administration.peripheralTemplates.emptyState.title'
            )}
            subTitle={i18n.t(
              'pages.administration.peripheralTemplates.emptyState.subTitle'
            )}
          />
        )}
        {peripheralTemplates &&
          peripheralTypes &&
          peripheralTemplates.length > 0 &&
          peripheralTypes.length > 0 && (
            <List
              templateItems={peripheralTemplates}
              typeItems={peripheralTypes}
              onShow={onShow}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          )}
      </Grid>
      {editTemplate && peripheralTypes && (
        <Dialog
          onClose={onEditCancel}
          aria-labelledby="edit-peripheral-templates-dialog"
          open
        >
          <Form
            peripheral={editTemplate}
            types={peripheralTypes || []}
            protocols={protocols || []}
            onSave={onSave}
            onCancel={onEditCancel}
          />
        </Dialog>
      )}
      {showTemplate && peripheralTypes && (
        <ItemDetail
          peripheralItem={showTemplate}
          protocols={protocols || []}
          types={peripheralTypes || []}
          onClose={onClose}
        />
      )}
    </Grid>
  );
};

export default PeripheralTemplates;

import { FunctionComponent, useEffect, useState } from 'react';
import {
  createStoryGenre,
  deleteStoryGenre,
  fetchStoryGenres,
  updateStoryGenre
} from 'actions/storyGenres';
import { Dialog } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { FormData } from 'pages/administration/story-genres/formData';
import List from 'pages/administration/story-genres/components/List';
import i18n from 'i18n';
import Form from 'pages/administration/story-genres/components/Form';
import toggleNotification from 'actions/notifications';
import TitleWrapper from 'components/TitleWrapper';
import {
  ProtectedButton,
  Resource,
  Action,
  useWithPermissions
} from 'components/Authorization';
import ContentBox from 'components/ContentBox';
import ContentBoxItem from 'components/ContentBoxItem';
import Search from 'components/Search';

const StoryGenres: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [storyGenres, setStoryGenres] = useState<$Lns.StoryGenre[]>([]);
  const [storyGenresOrig, setStoryGenresOrig] = useState<$Lns.StoryGenre[]>([]);
  const [editStoryGenre, setEditStoryGenre] = useState<$Lns.StoryGenre>();

  useEffect(() => {
    fetchStoryGenres().then(({ data }) => {
      setStoryGenres(data);
      setStoryGenresOrig(data);
    });
  }, []);

  const onRowClick = useWithPermissions(
    (rowItem: { name: { value: string } }) => {
      const {
        name: { value: nameValue }
      } = rowItem;

      const storyGenre = { guid: nameValue, key: nameValue, name: nameValue };
      setEditStoryGenre(storyGenre);
    },
    Resource.STORY_GENRE,
    Action.EDIT
  );

  if (storyGenresOrig.length < 1) return <></>;

  const onAdd = () => {
    setEditStoryGenre({
      key: '',
      name: '',
      guid: ''
    });
  };

  const onSave = (formData: FormData) => {
    let updatedStoryGenres = [];

    const storyGenre = {
      key: formData.key,
      name: formData.name
    } as $Lns.StoryGenre;

    if (formData.guid) {
      storyGenre.guid = formData.guid;
      updateStoryGenre(storyGenre).then(() => {
        updatedStoryGenres = storyGenres.map(sg => {
          if (sg.guid === storyGenre.guid) {
            return {
              ...sg,
              ...storyGenre
            };
          }
          return sg;
        });
        setStoryGenres(updatedStoryGenres);
        dispatch(
          toggleNotification(
            i18n.t('notifications.storyGenres.updateSuccess'),
            'success'
          )
        );
      });
    } else {
      createStoryGenre(storyGenre).then(({ data }) => {
        updatedStoryGenres = [data, ...storyGenres];
        setStoryGenres(updatedStoryGenres);
        dispatch(
          toggleNotification(
            i18n.t('notifications.storyGenres.createSuccess'),
            'success'
          )
        );
      });
    }
    setEditStoryGenre(undefined);
  };

  const onRemove = (storyGenre: $Lns.StoryGenre) => {
    deleteStoryGenre(storyGenre)
      .then(() => {
        const updatedStoryGenres = storyGenres.filter(
          sg => sg.guid !== storyGenre.guid
        );
        setStoryGenres(updatedStoryGenres);
        dispatch(
          toggleNotification(
            i18n.t('notifications.storyGenres.deleteSuccess'),
            'success'
          )
        );
      })
      .catch(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.storyGenres.deleteError'),
            'error'
          )
        );
      });
  };

  const onEdit = (storyGenre: $Lns.StoryGenre) => {
    setEditStoryGenre(storyGenre);
  };

  const onEditCancel = () => {
    setEditStoryGenre(undefined);
  };

  return (
    <>
      <ContentBox>
        <ContentBoxItem>
          <TitleWrapper
            title={i18n.t(
              'links.sidebarNestedItems.administration.storyGenres'
            )}
          />
          <ProtectedButton
            lnsResource={Resource.STORY_GENRE}
            lnsAction={Action.CREATE}
            color="secondary"
            variant="contained"
            onClick={onAdd}
          >
            {i18n.t('pages.administration.storyGenres.button.add')}
          </ProtectedButton>
        </ContentBoxItem>
        <ContentBoxItem>
          <div />
          <Search
            fields={storyGenresOrig}
            searchKeys={['key', 'name']}
            filterFields={setStoryGenres}
          />
        </ContentBoxItem>
        <List
          storyGenres={storyGenres}
          onRemove={onRemove}
          onEdit={onEdit}
          onRowClick={onRowClick}
        />
      </ContentBox>
      {editStoryGenre && (
        <Dialog onClose={onEditCancel} open>
          <Form
            onSave={onSave}
            storyGenre={editStoryGenre}
            onCancel={onEditCancel}
          />
        </Dialog>
      )}
    </>
  );
};

export default StoryGenres;

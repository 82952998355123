import {
  FunctionComponent,
  useEffect,
  useState,
  ChangeEvent,
  useCallback
} from 'react';
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Box,
  Dialog,
  Typography,
  InputLabel,
  Tooltip
} from '@material-ui/core';
import {
  getPeripheralAssets,
  searchPeripheralAssets,
  getPeripheralAsset
} from 'actions/peripheral';
import { Audiotrack } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { AssetDynamic } from 'pages/stories/interfaces/assetDynamic';
import { AssetType } from 'pages/stories/enums';
import { buildAssetItem } from 'pages/stories/components/Asset/utils';
import { FILE_TYPE_MAP } from 'pages/stories/components/Asset/constants';
import EmptyState from 'components/EmptyState';
import toggleNotification from 'actions/notifications';
import AssetForm from 'pages/stories/components/Asset/Form';
import assetManagerStyles from 'pages/stories/styles/assetManager';
import Loading from 'components/Loading';
import i18n from 'i18n';
import TextField from 'components/TextField';

interface PeripheralI {
  assetType: string;
  storyLock: boolean;
  protocol: $Lns.Peripheral | undefined;
  peripherals: $Lns.Peripheral[];
  onUpdateAssets: (asset: AssetDynamic) => void;
  onSelectPeripheral: (peripheral: $Lns.Peripheral) => void;
}

const AssetManager: FunctionComponent<PeripheralI> = ({
  assetType,
  storyLock,
  protocol,
  peripherals,
  onUpdateAssets,
  onSelectPeripheral
}: PeripheralI) => {
  const dispatch = useDispatch();
  const { guid } = useParams();
  const classes = assetManagerStyles();
  const [peripheralObjs, setPeripheralObjs] = useState(peripherals);
  const [showAsset, setShowAsset] = useState<$Lns.PeripheralAsset>();
  const [assetDetail, setAssetDetail] = useState<$Lns.ObjectKey[]>([]);
  const [selectedPref, setSelectedPref] = useState(protocol);
  const [wholeAsset, setWholeAsset] = useState<$Lns.PeripheralAsset[]>([]);
  const [isLoading, toggleLoading] = useState(false);

  const handleApiError = useCallback(() => {
    toggleLoading(false);
    dispatch(
      toggleNotification(i18n.t('notifications.stories.getAssetError'), 'error')
    );
  }, [dispatch]);

  const fetchAndSetAssets = useCallback(
    (selectedGuid: string) => {
      toggleLoading(true);
      getPeripheralAssets(selectedGuid)
        .then(({ data, code }) => {
          toggleLoading(false);
          if (code === 200) {
            setWholeAsset(data);
          }
        })
        .catch(handleApiError);
    },
    [handleApiError]
  );

  useEffect(() => {
    if (selectedPref) {
      fetchAndSetAssets(selectedPref.guid);
    }
  }, [selectedPref, fetchAndSetAssets]);

  useEffect(() => {
    setPeripheralObjs(peripherals);
    if (protocol) {
      setSelectedPref(protocol);
    }
  }, [peripherals, protocol]);

  const getAssets = (inputPref: $Lns.Peripheral) => {
    if (inputPref) fetchAndSetAssets(inputPref.guid);
  };

  const onEditCancel = () => {
    setShowAsset(undefined);
  };

  const onAdd = (pin: $Lns.PeripheralAsset) => {
    if (selectedPref) {
      const prefGuid = selectedPref?.guid;
      const assetGuid = pin.objectID;

      getPeripheralAsset(prefGuid, assetGuid)
        .then(({ data, code }) => {
          if (code === 200) {
            setAssetDetail([data]);
            setShowAsset(pin);
          }
        })
        .catch(handleApiError);
    }
  };

  const onCancel = () => {
    setShowAsset(undefined);
  };

  const onCreateStoryAsset = (totalDuration: number, trackPoints: number[]) => {
    const fileType = showAsset?.path.split('.') || [];
    if (fileType.length === 0) {
      fileType.push(FILE_TYPE_MAP[assetType]);
    }
    if (showAsset?.path) {
      const dynamicAsset = buildAssetItem(
        showAsset,
        guid,
        assetType,
        trackPoints,
        totalDuration
      );
      onUpdateAssets(dynamicAsset);
      onCancel();
    }
  };

  const onPreferalChange = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const { value } = e.target;
    const pref = peripheralObjs?.find(pin => value === pin.guid);
    if (pref) {
      setSelectedPref(pref);
      getAssets(pref);
      onSelectPeripheral(pref);
    }
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value?.length > 3) {
      searchAssets(value);
    }
    if (value === '' && selectedPref) {
      getAssets(selectedPref);
    }
  };

  const searchAssets = (query: string) => {
    if (!selectedPref) return;

    searchPeripheralAssets(selectedPref?.guid, query, assetType)
      .then(({ data, code }) => {
        if (code === 200) {
          setWholeAsset(data);
        }
      })
      .catch(handleApiError);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <FormControl margin="normal" variant="outlined" fullWidth>
              <InputLabel htmlFor="selectFormat-eyecandy">
                {i18n.t('pages.stories.placeHolder.peripheral')}
              </InputLabel>
              <Select
                fullWidth
                labelId="peripheral"
                id="peripheral"
                name="peripheral"
                label={i18n.t('pages.stories.placeHolder.peripheral')}
                required
                value={selectedPref?.guid || ''}
                onChange={pin => onPreferalChange(pin)}
                displayEmpty
              >
                {peripheralObjs?.map(peripheralObj => (
                  <MenuItem key={peripheralObj.guid} value={peripheralObj.guid}>
                    {peripheralObj.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="slug"
                label={i18n.t('pages.stories.placeHolder.search')}
                name="search"
                onChange={onSearch}
                autoComplete="Search"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {wholeAsset.map(pin => {
                  if (
                    assetType === AssetType.image &&
                    pin.type === AssetType.image
                  ) {
                    return (
                      <Grid
                        key={pin.objectID}
                        item
                        xs={3}
                        onClick={() => onAdd(pin)}
                      >
                        <img alt="" className={classes.root} src={pin.path} />
                        <Tooltip title={pin.name}>
                          <Typography variant="h6" noWrap>
                            {pin.name}
                          </Typography>
                        </Tooltip>
                      </Grid>
                    );
                  }
                  if (
                    assetType === AssetType.audio &&
                    pin.type === AssetType.audio
                  ) {
                    return (
                      <Grid
                        key={pin.objectID}
                        item
                        xs={3}
                        onClick={() => onAdd(pin)}
                      >
                        <Audiotrack className={classes.root} />
                        <Tooltip title={pin.name}>
                          <Typography variant="h6" noWrap>
                            {pin.name}
                          </Typography>
                        </Tooltip>
                      </Grid>
                    );
                  }
                  if (
                    assetType === AssetType.video &&
                    pin.type === AssetType.video
                  ) {
                    return (
                      <Grid
                        key={pin.objectID}
                        item
                        xs={3}
                        onClick={() => onAdd(pin)}
                      >
                        <img
                          alt=""
                          className={classes.root}
                          src={`data:image/gif;base64,${pin?.thumbnail}`}
                        />
                        <Tooltip title={pin.name}>
                          <Typography variant="h6" noWrap>
                            {pin.name}
                          </Typography>
                        </Tooltip>
                      </Grid>
                    );
                  }
                  return <></>;
                })}
              </Grid>
            </Grid>
          </Grid>
          {wholeAsset.length === 0 && (
            <Box padding={2}>
              <EmptyState title="" subTitle="No content yet" />
            </Box>
          )}
        </Grid>
      </Grid>
      {showAsset && (
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={onEditCancel}
          aria-labelledby="simple-dialog-title"
          open
        >
          <AssetForm
            storyLock={storyLock}
            asset={showAsset}
            assetDetail={assetDetail}
            onSave={onCreateStoryAsset}
            onCancel={onCancel}
          />
        </Dialog>
      )}
    </>
  );
};

export default AssetManager;

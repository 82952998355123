import { Chip, Tooltip, Box } from '@material-ui/core';
import {
  ArchiveOutlined,
  DeleteOutlined,
  DnsOutlined,
  EditOutlined
} from '@material-ui/icons';
import {
  BaseSyntheticEvent,
  FunctionComponent,
  useEffect,
  useState
} from 'react';
import { stateStyles } from 'pages/stories/styles';
import { secondsToDuration } from 'utils/time';
import { DropResult } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { getStoryState } from 'pages/stories/utils';
import StoryDetail from 'pages/rundowns/components/StoryDetail';
import i18n from 'i18n';
import TableBuilder from 'components/Table';
import {
  ProtectedIconButton,
  ProtectedDeleteDialog,
  Resource,
  Action
} from 'components/Authorization';
import ActionDialog from 'components/ActionDialogue';
import Search from 'components/Search';
import shortid from 'shortid';
import ContentBoxItem from 'components/ContentBoxItem';

interface StoriesI {
  stories: $Lns.Story[];
  storyPoolId?: string;
  onDeleteClick?: (story: $Lns.Story) => void;
  onArchiveClick?: (story: $Lns.Story) => void;
  onEditClick?: (story: $Lns.Story) => void;
  onDragComplete: (result: DropResult) => void;
  onAddRundown?: (story: $Lns.Story) => void;
}

const List: FunctionComponent<StoriesI> = ({
  stories,
  storyPoolId,
  onDeleteClick,
  onArchiveClick,
  onDragComplete,
  onAddRundown,
  onEditClick
}: StoriesI) => {
  const [filteredStories, setFilteredStories] = useState(stories);
  const [selectedStory, setSelectedStory] = useState<$Lns.Story>();

  const { storyStates } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  useEffect(() => {
    setFilteredStories(stories);
  }, [storyPoolId, stories]);

  const buildActionBtns = (story: $Lns.Story) => {
    const buttons = [];

    if (onEditClick) {
      buttons.push(
        <Tooltip title="Edit Story" aria-label="edit" key={shortid.generate()}>
          <ProtectedIconButton
            size="small"
            tooltip="Edit Story"
            aria-label="edit"
            onClick={(e: BaseSyntheticEvent) => {
              e.stopPropagation();
              onEditClick(story);
            }}
            lnsResource={Resource.STORY}
            lnsAction={Action.EDIT}
            lnsOwners={[story?.createdBy]}
          >
            <EditOutlined color="secondary" />
          </ProtectedIconButton>
        </Tooltip>
      );
    }

    if (onDeleteClick) {
      buttons.push(
        <ProtectedDeleteDialog
          size="small"
          key={shortid.generate()}
          onDelete={() => onDeleteClick(story)}
          target={story?.title}
          targetDeleteTitle="Delete Story"
          targetDeletePrompt="Are you sure?"
          targetDeleteWarning="Cannot be undone"
          icon={<DeleteOutlined color="secondary" />}
          btnDisabled={false}
          lnsResource={Resource.STORY}
          lnsOwners={[story?.createdBy]}
        />
      );
    }

    if (onArchiveClick) {
      buttons.push(
        <ActionDialog
          size="small"
          key={shortid.generate()}
          onConfirm={() => onArchiveClick(story)}
          target={story?.title}
          tooltip={i18n.t('pages.stories.tooltip.archiveStory')}
          icon={<ArchiveOutlined color="secondary" />}
          targetDeleteTitle={i18n.t('pages.stories.helperText.archiveStory')}
          targetDeletePrompt={i18n.t('deleteDialog.archiveDialog')}
          targetDeleteWarning={i18n.t('deleteDialog.warning')}
          btnDisabled={false}
        />
      );
    }

    if (onAddRundown) {
      buttons.push(
        <Tooltip title="Edit Story" aria-label="edit" key={shortid.generate()}>
          <ProtectedIconButton
            size="small"
            tooltip="Edit Story"
            aria-label="edit"
            onClick={() => onAddRundown(story)}
            lnsResource={Resource.STORY}
            lnsAction={Action.EDIT}
            lnsOwners={[story?.createdBy]}
          >
            <DnsOutlined color="secondary" />
          </ProtectedIconButton>
        </Tooltip>
      );
    }
    return buttons;
  };

  const getStateName = (guid: string) => {
    const storyState = storyStates.find(st => st.guid === guid);
    return storyState ? storyState.name : '';
  };

  const rows = [
    i18n.t('pages.stories.tableRow.pos'),
    i18n.t('pages.stories.tableRow.slug'),
    i18n.t('pages.stories.tableRow.title'),
    i18n.t('pages.stories.tableRow.state'),
    i18n.t('pages.stories.tableRow.plannedDuration'),
    i18n.t('pages.stories.tableRow.duration'),
    '' // Actions
  ];

  const stateClasses = stateStyles();

  const getStoryStateClass = (guid: string) => {
    const storyState = getStoryState(guid, storyStates);
    if (!storyState) return '';

    switch (storyState.name) {
      case 'draft':
        return stateClasses.draft;
      case 'pending approval':
        return stateClasses.pendingApproval;
      case 'approved':
        return stateClasses.approved;
      default:
        return stateClasses.draft;
    }
  };

  const cells = filteredStories.map((story, index: number) => {
    return {
      fields: {
        guid: { value: story?.guid, hidden: true },
        position: { value: index },
        slug: { value: story?.slug.toUpperCase() },
        title: { value: story?.title.toUpperCase() },
        story: {
          value: (
            <Chip
              classes={{ root: getStoryStateClass(story?.storyState) }}
              label={getStateName(story?.storyState)}
            />
          )
        },
        plannedDuration: { value: secondsToDuration(story?.plannedDuration) },
        duration: { value: secondsToDuration(story?.calculatedDuration) },
        actions: {
          value: <Box display="flex">{buildActionBtns(story)}</Box>
        }
      }
    };
  });

  const onClose = () => {
    setSelectedStory(undefined);
  };

  const showStory = (item: { guid: { value: string } }) => {
    const story = stories.find(pin => {
      return pin.guid === item.guid.value;
    });
    setSelectedStory(story);
  };

  return (
    <>
      <ContentBoxItem>
        <div />
        <Search
          fields={stories}
          searchKeys={['title', 'text', 'slug']}
          filterFields={setFilteredStories}
        />
      </ContentBoxItem>
      <TableBuilder
        rows={rows}
        cells={cells}
        isDraggable
        onRowClick={showStory}
        onDragComplete={onDragComplete}
      />
      {selectedStory && (
        <StoryDetail
          story={selectedStory}
          onClose={onClose}
          onEdit={onEditClick}
          onDelete={onDeleteClick}
        />
      )}
    </>
  );
};

export default List;

List.defaultProps = {
  storyPoolId: undefined,
  onDeleteClick: undefined,
  onArchiveClick: undefined,
  onEditClick: undefined,
  onAddRundown: undefined
};

import type { TextFieldProps } from '@material-ui/core';
import { Box, TextField as TextFieldCore } from '@material-ui/core';
import clsx from 'clsx';
import i18n from 'i18n';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import textFieldStyles from 'styles/textField';

const TextField: FunctionComponent<TextFieldProps> = ({
  label,
  variant,
  ...props
}: TextFieldProps) => {
  const classes = textFieldStyles();
  const { dir } = useSelector((state: $Lns.DefaultState) => state.default);
  const direction = dir ?? i18n.dir();

  return (
    <Box className={direction}>
      {label && <div className={classes.label}>{label}</div>}
      <TextFieldCore
        {...props}
        className={clsx(classes.textField, [
          variant,
          direction,
          props.className
        ])}
        InputProps={{ disableUnderline: true }}
      />
    </Box>
  );
};

export default TextField;

TextField.defaultProps = {
  variant: 'outlined'
};

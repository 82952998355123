import { FunctionComponent, useState } from 'react';
import { Divider, Tabs, Tab } from '@material-ui/core';
import { TabContext } from '@material-ui/lab';
import TabPanel from 'pages/administration/security/groups/components/TabPanel';
import { snakeToSpaces } from 'utils/string';

interface LnsAccessTabsI {
  lnsObjects: $Lns.LnsObject[];
  lnsObjectPermissions: $Lns.LnsObjectPermission[];
  onHandleChange: (
    newPermissions: $Lns.LnsObjectPermission,
    targetObject: $Lns.LnsObject
  ) => void;
}

const LnsAccessTabs: FunctionComponent<LnsAccessTabsI> = ({
  lnsObjects,
  lnsObjectPermissions,
  onHandleChange
}: LnsAccessTabsI) => {
  const [tabValue, setTabValue] = useState<string>(lnsObjects[0].guid);

  const onChange = (
    _: React.ChangeEvent<Record<string, unknown>>,
    newValue: string
  ) => {
    setTabValue(newValue);
  };

  return (
    <TabContext value={tabValue}>
      <Tabs
        value={tabValue}
        variant="scrollable"
        onChange={onChange}
        aria-label="lns object permission tabs"
      >
        {lnsObjects.map(obj => (
          <Tab
            label={snakeToSpaces(obj.name)}
            value={obj.guid}
            key={obj.guid}
          />
        ))}
      </Tabs>
      <Divider />
      {lnsObjects.map(obj => (
        <TabPanel
          lnsObject={obj}
          lnsObjectPermission={lnsObjectPermissions.find(
            perm => obj.guid === perm.lnsObject
          )}
          key={obj.guid}
          onHandleChange={onHandleChange}
        />
      ))}
      <Divider />
    </TabContext>
  );
};

export default LnsAccessTabs;

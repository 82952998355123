import { FunctionComponent, useEffect, useState, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Grid, IconButton, Paper } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { updateUser, fetchUsers } from 'actions/user';
import i18n from 'i18n';
import Form from 'pages/administration/security/users/components/Form';
import FormData from 'pages/administration/security/users/userFormData';
import UserList from 'pages/administration/security/users/components/List';
import ItemDetail from 'pages/administration/security/users/components/ItemDetail';
import storiesStyles from 'pages/stories/styles';
import toggleNotification from 'actions/notifications';
import TitleWrapper from 'components/TitleWrapper';
import TitleBarWrapper from 'components/TitleBarWrapper';
import SecurityIcon from '@material-ui/icons/Security';
import BasicTextField from 'components/BasicTextField';

interface UserListPageI {
  isAdmin?: boolean;
}

const UserListPage: FunctionComponent<UserListPageI> = ({
  isAdmin
}: UserListPageI) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState<$Lns.User[]>([]);
  const [usersOrig, setUsersOrig] = useState<$Lns.User[]>([]);
  const [editUserItem, setEditUserItem] = useState<$Lns.User>();
  const [showUser, setShowUser] = useState<$Lns.User>();
  const classes = storiesStyles();

  const handleApiError = () => {
    dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
  };

  const fetchSetUsers = () => {
    fetchUsers().then(({ data }) => {
      setUsers(data);
      setUsersOrig(data);
    });
  };

  useEffect(() => {
    fetchSetUsers();
  }, []);

  const onEdit = (userItem: $Lns.User) => {
    setEditUserItem(userItem);
  };

  const onEditCancel = () => {
    setEditUserItem(undefined);
  };

  const onSave = (formData: FormData) => {
    updateUser(formData)
      .then(() => {
        fetchSetUsers();
        dispatch(
          toggleNotification(
            i18n.t('notifications.users.editSuccess'),
            'success'
          )
        );
      })
      .catch(handleApiError);
    setEditUserItem(undefined);
  };

  const onClose = () => {
    setShowUser(undefined);
  };

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const newStorypool = usersOrig.filter(item => {
      return item.guid.includes(search) || item.username.includes(search);
    });
    setUsers(newStorypool);
  };

  return (
    <>
      <Grid container spacing={2}>
        <TitleBarWrapper>
          <Grid item xs={6}>
            <TitleWrapper
              title={i18n.t('links.sidebarNestedItems.administration.security')}
              subText={i18n.t('pages.stories.formLabel.title')}
              icon={<SecurityIcon color="primary" />}
            />
          </Grid>
        </TitleBarWrapper>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <BasicTextField
              className={classes.input}
              placeholder={i18n.t('placeholders.search')}
              onChange={onSearchFieldChange}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <UserList users={users} onEdit={onEdit} isAdmin={isAdmin || false} />
          {editUserItem && (
            <Dialog
              onClose={onEditCancel}
              aria-labelledby="edit-user-dialog"
              open
            >
              <Form
                user={editUserItem}
                onSave={onSave}
                onCancel={onEditCancel}
              />
            </Dialog>
          )}
          {showUser && <ItemDetail userItem={showUser} onClose={onClose} />}
        </Grid>
      </Grid>
    </>
  );
};

export default UserListPage;

UserListPage.defaultProps = {
  isAdmin: false
};

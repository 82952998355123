import { ChangeEvent, FunctionComponent } from 'react';
import { StoryFormData } from 'pages/stories/interface';
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import i18n from 'i18n';
import { generalStyle } from 'styles/generalStyle';
import TextField from 'components/TextField';

interface SimpleFieldsI {
  formData: StoryFormData;
  locations: $Lns.Location[];
  storyTypes: $Lns.StoryType[];
  storyGenres: $Lns.StoryGenre[];
  onChangeField: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeType: (e: ChangeEvent<{ name?: string; value: unknown }>) => void;
}

const SimpleFields: FunctionComponent<SimpleFieldsI> = ({
  formData,
  locations,
  storyTypes,
  storyGenres,
  onChangeField,
  onChangeType
}: SimpleFieldsI) => {
  const generalStyles = generalStyle();

  const getGenre = (guid: string) => {
    return storyGenres.find(sg => sg.guid === guid);
  };

  const getStoryType = (guid: string) => {
    return storyTypes.find(st => st.guid === guid);
  };

  const getLocation = (guid: string) => {
    return locations.find(l => l.guid === guid);
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="slug"
          className={generalStyles.textUppercase}
          label={i18n.t('pages.stories.formLabel.slug')}
          value={formData.slug}
          name="slug"
          onChange={onChangeField}
          error={!!formData?.errors?.slug}
          autoComplete="slug"
          autoFocus
        />
      </Grid>
      <Grid item>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="title"
          label={i18n.t('pages.stories.formLabel.title')}
          className={generalStyles.textUppercase}
          value={formData.title}
          name="title"
          onChange={onChangeField}
          error={!!formData?.errors?.title}
          autoComplete="title"
        />
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <Typography className={generalStyles.selectLabel}>
              {i18n.t('pages.stories.placeHolder.genre')}
            </Typography>
            <Select
              fullWidth
              labelId="genre"
              id="genre"
              name="genre"
              label={i18n.t('pages.stories.placeHolder.genre')}
              placeholder={i18n.t('pages.stories.placeHolder.genre')}
              required
              value={formData.genre}
              onChange={onChangeType}
              displayEmpty
              renderValue={(value: unknown) =>
                getGenre(value as string)?.name ||
                i18n.t('pages.stories.helperText.genre')
              }
            >
              {storyGenres.map(storyGenre => (
                <MenuItem key={storyGenre.key} value={storyGenre.guid}>
                  {storyGenre.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <Typography className={generalStyles.selectLabel}>
              {i18n.t('pages.stories.placeHolder.location')}
            </Typography>
            <Select
              labelId="location"
              id="location"
              name="location"
              label={i18n.t('pages.stories.placeHolder.location')}
              placeholder={i18n.t('pages.stories.placeHolder.location')}
              value={formData.location}
              onChange={onChangeType}
              displayEmpty
              renderValue={(value: unknown) =>
                getLocation(value as string)?.city ||
                i18n.t('pages.stories.helperText.location')
              }
            >
              {locations.map(location => {
                return (
                  <MenuItem key={location.guid} value={location.guid}>
                    {location.city}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item>
        <FormControl variant="outlined" fullWidth>
          <Typography className={generalStyles.selectLabel}>
            {i18n.t('pages.stories.placeHolder.type')}
          </Typography>
          <Select
            labelId="type"
            id="type"
            name="type"
            label={i18n.t('pages.stories.placeHolder.type')}
            placeholder={i18n.t('pages.stories.placeHolder.type')}
            required
            value={formData.type}
            onChange={onChangeType}
            displayEmpty
            renderValue={(value: unknown) =>
              getStoryType(value as string)?.name ||
              i18n.t('pages.stories.helperText.type')
            }
          >
            {storyTypes.map(storyType => (
              <MenuItem key={storyType.key} value={storyType.guid}>
                {storyType.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SimpleFields;

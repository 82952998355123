import { makeStyles } from '@material-ui/core';
import colors from 'styles/colors';
import themeStyle from 'styles/theme';

const aoConfirmationDialogStyles = makeStyles(theme => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  regenerate: {
    ...themeStyle.typography.smSemibold,
    padding: theme.spacing(1),
    borderRadius: 8,
    color: colors.gray[600],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(0.5),
    cursor: 'pointer',
    '&:hover': {
      color: colors.gray[700],
      background: colors.gray[200]
    }
  }
}));

export default aoConfirmationDialogStyles;

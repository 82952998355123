import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import i18n from 'i18n';
import TableBuilder from 'components/Table';
import TitleWrapper from 'components/TitleWrapper';
import ContentBox from 'components/ContentBox';
import ContentBoxItem from 'components/ContentBoxItem';

const Security: FunctionComponent = () => {
  const navigate = useNavigate();

  const onRowClick = (rowItem: { guid: { value: string } }) => {
    navigate(rowItem.guid.value);
  };

  const cells = [
    {
      fields: {
        name: {
          value: i18n.t('pages.administration.security.tableCell.users')
        },
        guid: { value: '/administration/security/users', hidden: true }
      }
    },
    {
      fields: {
        name: {
          value: i18n.t('pages.administration.security.tableCell.userGroups')
        },
        guid: { value: '/administration/security/user-groups', hidden: true }
      }
    }
  ];

  return (
    <ContentBox>
      <ContentBoxItem>
        <TitleWrapper
          title={i18n.t('links.sidebarNestedItems.administration.security')}
        />
      </ContentBoxItem>
      <TableBuilder rows={['Sub-menu']} cells={cells} onRowClick={onRowClick} />
    </ContentBox>
  );
};

export default Security;

import { FunctionComponent, useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  createPublishFolder,
  fetchPublishFolders,
  updatePublishFolder,
  removePublishFolder
} from 'actions/publishFolders';
import toggleNotification from 'actions/notifications';
import Form from 'pages/administration/publish-folders/components/Form';
import List from 'pages/administration/publish-folders/components/List';
import ItemDetail from 'pages/administration/publish-folders/components/ItemDetail';
import { FormData } from 'pages/administration/publish-folders/formData';
import i18n from 'i18n';
import TitleWrapper from 'components/TitleWrapper';
import BtnWrapper from 'components/BtnWrapper';
import { ProtectedButton, Resource, Action } from 'components/Authorization';
import ContentBox from 'components/ContentBox';
import ContentBoxItem from 'components/ContentBoxItem';
import Search from 'components/Search';

const PublishFolders: FunctionComponent = () => {
  const dispatch = useDispatch();

  const handleApiError = () => {
    dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
  };

  const [editPublishFolder, setEditPublishFolder] = useState<FormData>();

  const [showPublishFolder, setShowPublishFolder] =
    useState<$Lns.PublishFolder>();

  const [publishFolders, setPublishFolders] = useState<$Lns.PublishFolder[]>(
    []
  );

  const [publishedFoldersOrig, setPublishedFoldersOrig] = useState<
    $Lns.PublishFolder[]
  >([]);

  const fetchSetPublishFolders = () => {
    fetchPublishFolders().then(({ data }) => {
      setPublishFolders(data || []);
      setPublishedFoldersOrig(data || []);
    });
  };

  useEffect(() => {
    fetchSetPublishFolders();
  }, []);

  const onAdd = () => {
    setEditPublishFolder({ name: '', errors: { name: '' } });
  };

  const onRemove = (publishFolder: $Lns.PublishFolder) => {
    removePublishFolder(publishFolder).then(
      () => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.publishFolders.removeSuccess'),
            'success'
          )
        );
        fetchSetPublishFolders();
      },
      () => {
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
      }
    );
  };

  const onEdit = (publishFolder: $Lns.PublishFolder) => {
    const formData = { ...publishFolder, errors: { name: '' } };
    setEditPublishFolder(formData);
  };

  const onEditCancel = () => {
    setEditPublishFolder(undefined);
  };

  const onSave = (formData: FormData) => {
    if (formData.guid) {
      updatePublishFolder(formData as $Lns.PublishFolder)
        .then(() => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.publishFolders.saveSuccess'),
              'success'
            )
          );
          fetchSetPublishFolders();
        })
        .catch(handleApiError);
    } else {
      createPublishFolder(formData as $Lns.PublishFolder)
        .then(() => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.publishFolders.createSuccess'),
              'success'
            )
          );
          fetchSetPublishFolders();
        })
        .catch(handleApiError);
    }
    onEditCancel();
  };

  const onShow = (publishFolder: $Lns.PublishFolder) => {
    setShowPublishFolder(publishFolder);
  };

  const onClose = () => {
    setShowPublishFolder(undefined);
  };

  return (
    <>
      <ContentBox>
        <ContentBoxItem>
          <TitleWrapper
            title={i18n.t(
              'links.sidebarNestedItems.administration.publishFolders'
            )}
          />
          <BtnWrapper>
            <ProtectedButton
              color="secondary"
              variant="contained"
              onClick={onAdd}
              lnsResource={Resource.PUBLISH_FOLDER}
              lnsAction={Action.CREATE}
            >
              {i18n.t('pages.administration.publishFolders.button.add')}
            </ProtectedButton>
          </BtnWrapper>
        </ContentBoxItem>
        <ContentBoxItem>
          <div />
          <Search
            fields={publishedFoldersOrig}
            searchKeys={['guid', 'name']}
            filterFields={setPublishFolders}
          />
        </ContentBoxItem>
        <List
          publishFolders={publishFolders}
          onShow={onShow}
          onRemove={onRemove}
          onEdit={onEdit}
        />
      </ContentBox>

      {editPublishFolder && (
        <Dialog
          onClose={onEditCancel}
          aria-labelledby="simple-dialog-title"
          open
        >
          <Form
            onSave={onSave}
            onCancel={onEditCancel}
            publishFolder={editPublishFolder}
            publishFolders={publishFolders}
          />
        </Dialog>
      )}

      {showPublishFolder && (
        <ItemDetail item={showPublishFolder} onClose={onClose} />
      )}
    </>
  );
};

export default PublishFolders;

import type { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const contentItemStyles = makeStyles((theme: Theme) => ({
  item: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&>div:only-child': {
      flex: 1
    }
  },
  itemVertical: {
    flexDirection: 'column',
    alignItems: 'start'
  }
}));

export default contentItemStyles;

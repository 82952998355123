import { FunctionComponent, useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import FileTypeList from 'pages/administration/file-types/components/List';
import {
  createFileType,
  deleteFileType,
  fetchFileTypes,
  updateFileType
} from 'pages/administration/file-types/actions';
import i18n from 'i18n';
import Form from 'pages/administration/file-types/components/Form';
import { useDispatch } from 'react-redux';
import toggleNotification from 'actions/notifications';
import { FormData } from 'pages/administration/file-types/formData';
import { objectsWithItemRemoved, updatedObjects } from 'utils/state';
import TitleWrapper from 'components/TitleWrapper';
import { ProtectedButton, Resource, Action } from 'components/Authorization';
import ContentBox from 'components/ContentBox';
import ContentBoxItem from 'components/ContentBoxItem';
import Search from 'components/Search';

const FileTypes: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [fileTypes, setFileTypes] = useState<$Lns.FileType[]>([]);
  const [fileTypesOrig, setFileTypesOrig] = useState<$Lns.FileType[]>([]);
  const [editFileType, setEditFileType] = useState<$Lns.FileType>();

  useEffect(() => {
    fetchFileTypes().then(({ data }) => {
      const newFileTypes = data.slice().reverse();
      setFileTypes(newFileTypes);
      setFileTypesOrig(newFileTypes);
    });
  }, []);

  const onAdd = () => {
    const newFileType = { name: '', guid: '' };
    setEditFileType(newFileType);
  };

  const onCancel = () => {
    setEditFileType(undefined);
  };

  const onEdit = (fileType: $Lns.FileType) => {
    setEditFileType(fileType);
  };

  const onCreate = (fileType: $Lns.FileType) => {
    createFileType(fileType)
      .then(({ data, code }) => {
        if (code === 201) {
          dispatch(
            toggleNotification(
              i18n.t('notifications.fileTypes.createSuccess'),
              'success'
            )
          );
          const updatedFilesTypes = [data, ...fileTypes];
          setFileTypes(updatedFilesTypes);
        }
      })
      .catch(() =>
        dispatch(
          toggleNotification(
            i18n.t('notifications.fileTypes.createError'),
            'error'
          )
        )
      );
  };

  const onUpdate = (fileType: $Lns.FileType) => {
    updateFileType(fileType)
      .then(json => {
        if (json.code === 201) {
          dispatch(
            toggleNotification(
              i18n.t('notifications.fileTypes.updateSuccess'),
              'success'
            )
          );
          const updatedFilesTypes = updatedObjects(fileTypes, fileType);
          setFileTypes(updatedFilesTypes);
        }
      })
      .catch(() =>
        dispatch(
          toggleNotification(
            i18n.t('notifications.fileTypes.updateError'),
            'error'
          )
        )
      );
  };

  const onDelete = (fileType: $Lns.FileType) => {
    deleteFileType(fileType)
      .then(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.fileTypes.deleteSuccess'),
            'success'
          )
        );
        const updatedFilesTypes = objectsWithItemRemoved(fileType, fileTypes);
        setFileTypes(updatedFilesTypes);
      })
      .catch(() => {
        toggleNotification(
          i18n.t('notifications.fileTypes.deleteError'),
          'error'
        );
      });
  };

  const onSave = (formData: FormData) => {
    if (formData.guid) {
      const fileType = {
        name: formData.name,
        extension: formData.extension,
        guid: formData.guid
      };
      onUpdate(fileType);
    } else {
      const fileType = { name: formData.name, extension: formData.extension };
      onCreate(fileType);
    }

    setEditFileType(undefined);
  };

  return (
    <>
      <ContentBox>
        <ContentBoxItem>
          <TitleWrapper
            title={i18n.t('links.sidebarNestedItems.administration.fileTypes')}
          />
          <ProtectedButton
            lnsResource={Resource.FILE_TYPE}
            lnsAction={Action.CREATE}
            color="secondary"
            variant="contained"
            onClick={onAdd}
          >
            {i18n.t('pages.administration.fileTypes.button.add')}
          </ProtectedButton>
        </ContentBoxItem>
        <ContentBoxItem>
          <div />
          <Search
            fields={fileTypesOrig}
            searchKeys={['guid', 'name']}
            filterFields={setFileTypes}
          />
        </ContentBoxItem>
        <FileTypeList
          fileTypes={fileTypes}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </ContentBox>
      {editFileType && (
        <Dialog onClose={onCancel} aria-labelledby="edit-storypool" open>
          <Form fileType={editFileType} onSave={onSave} onCancel={onCancel} />
        </Dialog>
      )}
    </>
  );
};

export default FileTypes;

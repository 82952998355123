import { FunctionComponent, useEffect, useState } from 'react';
import PublishFolders from 'pages/publish-folders/components/List';
import { useNavigate } from 'react-router';
import { fetchPublishFolders } from 'actions/publishFolders';
import toggleNotification from 'actions/notifications';
import { useDispatch } from 'react-redux';
import TitleWrapper from 'components/TitleWrapper';
import i18n from 'i18n';
import ContentBox from 'components/ContentBox';
import ContentBoxItem from 'components/ContentBoxItem';
import Search from 'components/Search';

const ListPublishedFolders: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [publishedFolders, setPublishedFolders] = useState<
    $Lns.PublishFolder[]
  >([]);

  const [publishedFoldersOrig, setPublishedFoldersOrig] = useState<
    $Lns.PublishFolder[]
  >([]);

  useEffect(() => {
    fetchPublishFolders()
      .then(({ data }) => {
        setPublishedFolders(data || []);
        setPublishedFoldersOrig(data || []);
      })
      .catch(() =>
        dispatch(toggleNotification('Failed to load publish folders', 'error'))
      );
  }, [dispatch]);

  const onRowClick = (rowItem: {
    guid: { value: string };
    name: { value: string };
  }) => {
    const {
      guid: { value: guidValue },
      name: { value: nameValue }
    } = rowItem;

    navigate(`/publish-folders/${guidValue}`, { state: { name: nameValue } });
  };

  const onEditClick = (rowItem: $Lns.PublishFolder) => {
    navigate(`/publish-folders/${rowItem.guid}/edit`, {
      state: { name: rowItem.name }
    });
  };

  return (
    <ContentBox>
      <ContentBoxItem>
        <TitleWrapper title={i18n.t('links.sidebar.publishFolders')} />
        <Search
          fields={publishedFoldersOrig}
          searchKeys={['guid', 'name']}
          filterFields={setPublishedFolders}
        />
      </ContentBoxItem>
      <PublishFolders
        publishFolders={publishedFolders}
        onRowClick={onRowClick}
        onEditClick={onEditClick}
      />
    </ContentBox>
  );
};

export default ListPublishedFolders;

import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import EmptyState from 'components/EmptyState';
import {
  fetchNewswireServices,
  fetchNewswireFormats
} from 'pages/newswires/actions';
import NewswireServices from 'pages/newswires/components/NewswireServices';
import i18n from 'i18n';
import { useDispatch } from 'react-redux';
import toggleNotification from 'actions/notifications';
import TitleWrapper from 'components/TitleWrapper';
import { useWithPermissions, Resource, Action } from 'components/Authorization';
import ContentBox from 'components/ContentBox';
import ContentBoxItem from 'components/ContentBoxItem';
import Search from 'components/Search';

const NewswiresContainer: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [newswires, setNewswires] = useState<$Lns.NewswireService[]>([]);

  const [newswiresOrig, setNewswiresOrig] = useState<$Lns.NewswireService[]>(
    []
  );

  const [formatList, setFormatList] = useState<$Lns.NewswireFormat[]>([]);

  useEffect(() => {
    fetchNewswireServices()
      .then(({ data }) => {
        setNewswires(data);
        setNewswiresOrig(data);
      })
      .catch(() =>
        dispatch(toggleNotification('Failed to load storypools', 'error'))
      );
    fetchNewswireFormats().then(({ data }) => {
      setFormatList(data);
    });
  }, [dispatch]);

  const onRowClick = useWithPermissions(
    (rowItem: { guid: { value: string } }) => {
      const {
        guid: { value: guidValue }
      } = rowItem;

      navigate(`/newswires/${guidValue}/stories`);
    },
    Resource.NEWSWIRE,
    Action.READ
  );

  if (newswiresOrig.length < 1)
    return (
      <EmptyState
        title={i18n.t('pages.administration.newswires.emptyState.title')}
        subTitle={i18n.t('pages.administration.newswires.emptyState.subTitle')}
      />
    );

  return (
    <ContentBox>
      <ContentBoxItem>
        <TitleWrapper title={i18n.t('links.sidebar.newswires')} />
        <Search
          fields={newswiresOrig}
          searchKeys={['guid', 'name']}
          filterFields={setNewswires}
        />
      </ContentBoxItem>

      <NewswireServices
        newswireServices={newswires}
        newswireFormats={formatList}
        onRowClick={onRowClick}
      />
    </ContentBox>
  );
};

export default NewswiresContainer;

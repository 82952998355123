import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'components/Loading';
import { FormData, defaultState } from 'pages/assignments/formData';
import AssignmentHeader from 'pages/assignments/components/Header';
import ListAssignments from 'pages/assignments/components/AssignmentList';
import DialogDetails from 'pages/assignments/components/DialogDetails';
import DialogForm from 'pages/assignments/components/DialogForm';
import {
  fetchAssignments,
  createAssignment,
  updateAssignment,
  deleteAssignment
} from 'pages/assignments/actions';
import { LnsTaskToFormData, findTaskByGuid } from 'pages/assignments/utils';
import { useHasFullResourceAccess } from 'components/Authorization/utils';
import toggleNotification from 'actions/notifications';
import { socket } from 'components/RealTimeUpdates';
import i18n from 'i18n';
import ContentBox from 'components/ContentBox';

const Assignments: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isAdmin = useHasFullResourceAccess('TASK');
  const [assignments, setAssignments] = useState<$Lns.Task[]>();
  const [viewAssignment, setViewAssignment] = useState<FormData>();
  const [editAssignment, setEditAssignment] = useState<FormData>();

  const { taskStates, currentLnsUser } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  const fetchSetAssignments = useCallback(() => {
    fetchAssignments()
      .then(({ data }) => setAssignments(data))
      .catch(() =>
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'))
      );
  }, [dispatch]);

  const onViewAssignment = (assignment: $Lns.Task) => {
    setViewAssignment(LnsTaskToFormData(assignment));
  };

  const onCancelView = () => {
    setViewAssignment(undefined);
  };

  const addAssignment = () => {
    setEditAssignment(defaultState);
  };

  const onEditAssignment = (assignment: $Lns.Task) => {
    setEditAssignment(LnsTaskToFormData(assignment));
  };

  const onHandleEdit = (editTask: $Lns.Task) => {
    if (assignments) {
      const assignment = findTaskByGuid(editTask?.guid, assignments);
      if (assignment) onEditAssignment(assignment);
    }
  };

  const onSaveForm = (formData: FormData) => {
    if (formData.guid) {
      updateAssignment(formData).then(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.assignments.updateSuccess'),
            'success'
          )
        );
      });
    } else {
      createAssignment(formData).then(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.assignments.createSuccess'),
            'success'
          )
        );
      });
    }
    setEditAssignment(undefined);
  };

  const onCancelForm = () => {
    setEditAssignment(undefined);
  };

  const onDeleteAssignment = (assignmentGuid: string) => {
    deleteAssignment(assignmentGuid).then(() => {
      dispatch(
        toggleNotification(
          i18n.t('notifications.assignments.deleteSuccess'),
          'success'
        )
      );
    });
  };

  useEffect(() => {
    fetchSetAssignments();
  }, [fetchSetAssignments]);

  // To listen to the socket to auto refresh the page
  useEffect(() => {
    // Component Did Mount Portion
    socket.off('assignment-list-refresh', fetchSetAssignments);
    socket.on('assignment-list-refresh', fetchSetAssignments);

    // Component Will UnMount Portion
    return () => {
      socket.off('assignment-list-refresh', fetchSetAssignments);
    };
  }, [fetchSetAssignments]);

  // Loading Screen
  if (
    isAdmin === undefined ||
    assignments === undefined ||
    taskStates === undefined
  )
    return <Loading />;
  // END Loading Screen

  return (
    <ContentBox>
      <AssignmentHeader onAddCallback={addAssignment} />
      {assignments && (
        <ListAssignments
          isAdmin={isAdmin}
          currentLnsUser={currentLnsUser}
          assignments={assignments}
          onViewAssignment={onViewAssignment}
          onEditAssignment={onEditAssignment}
          onDeleteAssignment={onDeleteAssignment}
        />
      )}
      {!assignments && <Loading />}
      {editAssignment && (
        <DialogForm
          isAdmin={isAdmin}
          currentLnsUser={currentLnsUser}
          assignment={editAssignment}
          onSave={onSaveForm}
          onCancel={onCancelForm}
          taskStates={taskStates}
        />
      )}
      {viewAssignment && (
        <DialogDetails
          assignment={viewAssignment}
          currentLnsUser={currentLnsUser}
          isAdmin={isAdmin}
          taskStates={taskStates}
          onEdit={onHandleEdit}
          onSave={onSaveForm}
          onDelete={onDeleteAssignment}
          onClose={onCancelView}
        />
      )}
    </ContentBox>
  );
};

export default Assignments;

import type { ReactNode } from 'react';
import { Box } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import contentStyles from 'styles/contentBoxItem';
import clsx from 'clsx';

interface ContentBoxItemI {
  vertical?: boolean;
  children?: ReactNode | ReactNode[];
}

const ContentBoxItem: FunctionComponent<ContentBoxItemI> = ({
  vertical,
  children
}: ContentBoxItemI) => {
  const classes = contentStyles();

  return (
    <Box className={clsx(classes.item, { [classes.itemVertical]: vertical })}>
      {children}
    </Box>
  );
};

export default ContentBoxItem;

ContentBoxItem.defaultProps = {
  vertical: false,
  children: undefined
};

import { FunctionComponent, useEffect, useState, useCallback } from 'react';
import { Dialog } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  fetchRundownTemplates,
  createRundownTemplate,
  updateRundownTemplate,
  deleteRundownTemplate
} from 'pages/administration/rundown-templates/actions';
import RundownTemplateList from 'pages/administration/rundown-templates/components/List';
import Form from 'pages/administration/rundown-templates/components/Form';
import toggleNotification from 'actions/notifications';
import i18n from 'i18n';
import TitleWrapper from 'components/TitleWrapper';
import BtnWrapper from 'components/BtnWrapper';
import { ProtectedButton, Resource, Action } from 'components/Authorization';
import ContentBox from 'components/ContentBox';
import ContentBoxItem from 'components/ContentBoxItem';
import Search from 'components/Search';

const RundownTemplates: FunctionComponent = () => {
  const [rundownTemplates, setRundownTemplates] = useState<
    $Lns.RundownTemplate[]
  >([]);

  const [rundownTemplatesOrig, setRundownTemplatesOrig] = useState<
    $Lns.RundownTemplate[]
  >([]);

  const [editTemplate, setEditTemplate] = useState<$Lns.RundownTemplate>();
  const dispatch = useDispatch();

  const fetchSetRundownTemplates = useCallback(() => {
    fetchRundownTemplates()
      .then(({ data }) => {
        setRundownTemplates(data);
        setRundownTemplatesOrig(data);
      })
      .catch(() => {
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
      });
  }, [dispatch]);

  useEffect(() => {
    fetchSetRundownTemplates();
  }, [fetchSetRundownTemplates]);

  const onEditCancel = () => {
    setEditTemplate(undefined);
  };

  const onEdit = (rundownTemplate: $Lns.RundownTemplate) => {
    setEditTemplate(rundownTemplate);
  };

  const onSaveEdit = (formData: $Lns.RundownTemplate) => {
    updateRundownTemplate(formData)
      .then(() => {
        // resolve
        fetchSetRundownTemplates();
        setEditTemplate(undefined);
        dispatch(
          toggleNotification(
            i18n.t('notifications.rundownTemplates.updateSuccess'),
            'success'
          )
        );
      })
      .catch(() => {
        // reject
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
      });
  };

  const onAdd = () => {
    setEditTemplate({
      name: '',
      playoutStartTime: '00:00',
      playoutEndTime: '00:00',
      schedule: {
        mo: false,
        tu: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false
      }
    } as $Lns.RundownTemplate);
  };

  const onSave = (formData: $Lns.RundownTemplate) => {
    if (formData.guid) {
      onSaveEdit(formData);
    } else {
      onCreate(formData);
    }
  };

  const onCreate = (formData: $Lns.RundownTemplate) => {
    createRundownTemplate(formData)
      .then(() => {
        // resolve
        fetchSetRundownTemplates();
        setEditTemplate(undefined);
        dispatch(
          toggleNotification(
            i18n.t('notifications.rundownTemplates.createSuccess'),
            'success'
          )
        );
      })
      .catch(() => {
        // reject
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
      });
  };

  const onDelete = (deleteTarget: $Lns.RundownTemplate) => {
    deleteRundownTemplate(deleteTarget)
      .then(() => {
        fetchSetRundownTemplates();
        dispatch(
          toggleNotification(
            i18n.t('notifications.rundownTemplates.deleteSuccess'),
            'success'
          )
        );
      })
      .catch(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.rundownTemplates.deleteError'),
            'error'
          )
        );
      });
  };

  return (
    <>
      <ContentBox>
        <ContentBoxItem>
          <TitleWrapper
            title={i18n.t(
              'links.sidebarNestedItems.administration.rundownTemplates'
            )}
          />
          <BtnWrapper>
            <ProtectedButton
              lnsResource={Resource.RUNDOWN_TEMPLATE}
              lnsAction={Action.CREATE}
              color="secondary"
              variant="contained"
              onClick={onAdd}
            >
              {i18n.t('pages.administration.rundownTemplates.button.add')}
            </ProtectedButton>
          </BtnWrapper>
        </ContentBoxItem>
        <ContentBoxItem>
          <div />
          <Search
            fields={rundownTemplatesOrig}
            searchKeys={['guid', 'name']}
            filterFields={setRundownTemplates}
          />
        </ContentBoxItem>
        <RundownTemplateList
          rundownTemplates={rundownTemplates}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </ContentBox>
      {editTemplate && (
        <Dialog
          onClose={onEditCancel}
          aria-labelledby="simple-dialog-title"
          open
        >
          <Form
            rundownTemplate={editTemplate}
            onSave={onSave}
            onCancel={onEditCancel}
          />
        </Dialog>
      )}
    </>
  );
};

export default RundownTemplates;

import { makeStyles } from '@material-ui/core';

const contentStyles = makeStyles({
  sideContent: {
    flex: 1,
    height: 'fit-content',
    maxWidth: 380,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start'
  }
});

export default contentStyles;

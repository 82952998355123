import { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import TitleWrapper from 'components/TitleWrapper';
import { ProtectedButton, Resource, Action } from 'components/Authorization';
import ContentBoxItem from 'components/ContentBoxItem';

interface AssignmentHeaderI {
  onAddCallback: () => void;
}

const AssignmentHeader: FunctionComponent<AssignmentHeaderI> = ({
  onAddCallback
}: AssignmentHeaderI) => {
  const onAdd = () => {
    onAddCallback();
  };

  return (
    <ContentBoxItem>
      <TitleWrapper title="Assignments" />
      <Box
        display="flex"
        gridTemplateColumns="max-content max-content"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        height="100%"
        gridGap={10}
      >
        <ProtectedButton
          lnsResource={Resource.TASK}
          lnsAction={Action.CREATE}
          color="secondary"
          variant="contained"
          onClick={onAdd}
        >
          Create Assignment
        </ProtectedButton>
      </Box>
    </ContentBoxItem>
  );
};

export default AssignmentHeader;

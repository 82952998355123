import { FunctionComponent } from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
  Divider,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { Subject, Link as LinkIcon, Schedule } from '@material-ui/icons';
import {
  ProtectedButton,
  WithPermissions,
  Resource,
  Action
} from 'components/Authorization';
import DeleteDialog from 'components/DeleteDialog';
import DetailDialog from 'components/DetailDialog';
import i18n from 'i18n';
import BtnWrapper from 'components/BtnWrapper';

interface StoryDetailI {
  story: $Lns.Story;
  onEdit?: (story: $Lns.Story) => void;
  onDelete?: (story: $Lns.Story) => void;
  onClose: () => void;
}

const noDataString = i18n.t('noData');

const StoryDetail: FunctionComponent<StoryDetailI> = ({
  story,
  onEdit,
  onDelete,
  onClose
}: StoryDetailI) => {
  const dialogActions = () => {
    return (
      <Box width="100%" display="flex" paddingX={1} alignItems="center">
        {onDelete && (
          <Box paddingX={1}>
            <WithPermissions
              lnsResource="task"
              lnsAction="edit"
              disable={false}
            >
              <DeleteDialog
                lnsResource="story"
                lnsAction="delete"
                onDelete={() => onDelete(story)}
                tooltip={i18n.t('pages.assignments.tooltip.delete')}
                target={story.title}
                targetDeleteTitle={i18n.t('pages.stories.helperText')}
                targetDeletePrompt={i18n.t('deleteDialog.prompt')}
                targetDeleteWarning={i18n.t('deleteDialog.warning')}
                btnDisabled={false}
              />
            </WithPermissions>
          </Box>
        )}
        <Box display="flex" flex="1" justifyContent="flex-end">
          <BtnWrapper>
            {onEdit && (
              <ProtectedButton
                tooltip="Edit Story"
                color="secondary"
                variant="contained"
                onClick={() => onEdit(story)}
                lnsResource={Resource.STORY}
                lnsAction={Action.EDIT}
                lnsOwners={[story.createdBy]}
              >
                {i18n.t('button.edit')}
              </ProtectedButton>
            )}
            <Button variant="outlined" color="secondary" onClick={onClose}>
              {i18n.t('button.close')}
            </Button>
          </BtnWrapper>
        </Box>
      </Box>
    );
  };

  const dialogContent = () => {
    return (
      <Box>
        <List>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <Subject />
            </ListItemIcon>
            <ListItemText
              primary={story?.title || noDataString}
              secondary={i18n.t('pages.assignments.formLabel.storyTitle')}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText
              primary={story?.slug || noDataString}
              secondary={i18n.t('pages.assignments.formLabel.storySlug')}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <Schedule />
            </ListItemIcon>
            <ListItemText
              primary={story?.calculatedDuration || noDataString}
              secondary={i18n.t('pages.stories.formLabel.createdby')}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Subject />
            </ListItemIcon>
            <ListItemText
              primary={story?.state || noDataString}
              secondary={i18n.t('pages.stories.formLabel.state')}
            />
          </ListItem>
        </List>
      </Box>
    );
  };

  return (
    <DetailDialog
      dialogTitle={story.title}
      dialogContent={dialogContent()}
      dialogActions={dialogActions()}
      onHandleClose={onClose}
    />
  );
};

export default StoryDetail;
StoryDetail.defaultProps = {
  onEdit: undefined,
  onDelete: undefined
};

import { PaletteType } from '@material-ui/core';
import colors from 'styles/colors';

const themeStyle = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 719,
      md: 1023,
      lg: 1200,
      xl: 1920
    }
  },
  typography: {
    fontFamily:
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "WorkSans", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    fontSize: 12,
    h1: {
      fontSize: 18,
      fontWeight: 600,
      color: colors.gray[900]
    },
    h2: {
      color: colors.gray[900]
    },
    h3: {
      color: colors.gray[900]
    },
    h4: {
      fontSize: 18,
      fontWeight: 600,
      color: colors.gray[900]
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 'normal'
    },
    smMedium: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 500
    },
    smSemibold: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 600
    },
    mdRegular: {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 400
    }
  },
  palette: {
    background: {
      default: colors.base.white
    },
    type: 'light' as PaletteType,
    success: {
      light: colors.success[100],
      main: colors.success[400],
      dark: colors.success[700]
    },
    warning: {
      light: colors.warning[100],
      main: colors.warning[400],
      dark: colors.warning[700]
    },
    info: {
      light: colors.info.light,
      main: colors.info.medium,
      dark: colors.info.dark
    },
    error: {
      light: colors.error[100],
      main: colors.error[400],
      dark: colors.error[700]
    },
    primary: {
      main: colors.gray[500],
      light: colors.gray[300],
      dark: colors.gray[700],
      contrastText: colors.neutral[0]
    },
    secondary: {
      light: colors.gray[100],
      main: colors.gray[400],
      dark: colors.gray[900],
      contrastText: colors.gray[25]
    },
    text: {
      primary: colors.gray[900],
      secondary: colors.gray[600]
    }
  },
  overrides: {
    // Style sheet name ⚛️
    MuiTabs: {
      root: {
        padding: 4,
        border: `1px solid ${colors.gray[200]}`,
        borderRadius: '10px',
        height: 'fit-content',
        minHeight: 40,
        background: colors.gray[50],
        '& .MuiTabs-scroller': {
          width: 0
        }
      },
      flexContainer: {
        position: 'inherit',
        zIndex: 2 /* To render the component (text specifically) above the indicator */
      },
      scroller: {
        margin: '-4px',
        /* MaterialUI adds `marginBottom: 0` manually. We need to override this to position our tabs correctly. */
        marginBottom: '-4px !important',
        padding: '4px'
      },
      indicator: {
        background: colors.base.white,
        height: 'calc(100% - 8px)',
        marginBottom: 4,
        borderRadius: 6,
        boxShadow:
          '0px 1px 3px 0px rgba(10, 13, 18, 0.10), 0px 1px 2px -1px rgba(10, 13, 18, 0.10)'
      }
    },
    MuiTab: {
      root: {
        lineHeight: '20px',
        borderRadius: 6,
        padding: '8px 12px',
        '@media': {
          minWidth: 80,
          minHeight: 'auto'
        }
      },
      wrapper: {
        textTransform: 'none'
      }
    },
    MuiFormLabel: {
      root: {
        '&.Mui-error': {
          color: colors.error[700]
        }
      }
    },
    MuiCheckbox: {
      root: {
        color: colors.primary[500]
      }
    },
    MuiTableCell: {
      root: {
        fontWeight: 600
      },
      sizeSmall: {
        padding: '10px 10px',
        fontSize: '14px',
        fontWeight: 400
      }
    },
    MuiTypography: {
      body1: {
        fontWeight: 600
      }
    },
    MuiButton: {
      root: {
        padding: '10px 16px',
        fontWeight: 600,
        lineHeight: 'normal',
        textTransform: 'none',
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 6,
        '&:disabled': {
          color: ''
        },
        '&.Mui-disabled': {
          background: `${colors.neutral[5]} !important`,
          border: '1px solid rgba(0, 0, 0, 0.1)'
        }
      },
      contained: {
        padding: '10px 16px',
        boxShadow: 'none'
      },
      outlined: {
        padding: '10px 16px',
        boxShadow: 'none'
      },
      containedPrimary: {
        color: colors.base.white,
        backgroundColor: colors.brand[600],
        borderColor: colors.brand[300],
        '&:hover': {
          backgroundColor: colors.brand[700]
        }
      },
      outlinedPrimary: {
        color: colors.brand[700],
        borderColor: colors.brand[300],
        '&:hover': {
          color: colors.brand[800],
          borderColor: colors.brand[400]
        }
      },
      textPrimary: {
        color: colors.brand[600],
        backgroundColor: 'inherit',
        boxShadow: 'none',
        '&:hover': {
          color: colors.brand[700]
        }
      },
      containedSecondary: {
        color: colors.base.white,
        backgroundColor: colors.gray[600],
        borderColor: colors.gray[300],
        '&:hover': {
          backgroundColor: colors.gray[700]
        }
      },
      outlinedSecondary: {
        color: colors.gray[700],
        borderColor: colors.gray[300],
        '&:hover': {
          color: colors.gray[800],
          borderColor: colors.gray[400]
        }
      },
      textSecondary: {
        color: colors.gray[600],
        backgroundColor: 'inherit',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          color: colors.gray[700]
        }
      }
    },
    MuiInputBase: {
      input: {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        gap: 8
      }
    },
    MuiOutlinedInput: {
      root: {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: 'none'
        }
      }
    },
    MuiDialog: {
      paper: {
        padding: 24,
        borderRadius: 12
      }
    },
    MuiSelect: {
      root: {
        padding: '10px 14px',
        borderRadius: 8,
        background: colors.base.white,
        border: `1px solid ${colors.gray[300]}`,
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        '&:hover': {
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(158, 119, 237, 0.24)'
        },
        '&:focus': {
          borderRadius: '8px !important',
          background: `${colors.gray[50]} !important`
        }
      }
    }
  }
};

export default themeStyle;

import { Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';

interface TitleBarWrapperI {
  title: string;
  value: string;
}

const DialogueWrapper: FunctionComponent<TitleBarWrapperI> = ({
  title,
  value
}: TitleBarWrapperI) => {
  return (
    <Typography noWrap variant="subtitle1">
      <Typography component="span">{title}</Typography>:{' '}
      <Typography variant="inherit" component="span">
        {value}
      </Typography>
    </Typography>
  );
};

export default DialogueWrapper;

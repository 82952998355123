import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import i18n from 'i18n';
import TableBuilder from 'components/Table';
import TitleWrapper from 'components/TitleWrapper';
import ContentBox from 'components/ContentBox';
import ContentBoxItem from 'components/ContentBoxItem';

const Peripherals: FunctionComponent = () => {
  const navigate = useNavigate();

  const onRowClick = (rowItem: { guid: { value: string } }) => {
    navigate(rowItem.guid.value);
  };

  const cells = [
    {
      fields: {
        name: {
          value: i18n.t(
            'pages.administration.peripheralSettings.tableCell.peripherals'
          )
        },
        guid: {
          value: '/administration/peripheral-settings/peripherals',
          hidden: true
        }
      }
    },
    {
      fields: {
        name: {
          value: i18n.t(
            'pages.administration.peripheralSettings.tableCell.templates'
          )
        },
        guid: {
          value: '/administration/peripheral-settings/templates',
          hidden: true
        }
      }
    },
    {
      fields: {
        name: {
          value: i18n.t(
            'pages.administration.peripheralSettings.tableCell.types'
          )
        },
        guid: {
          value: '/administration/peripheral-settings/types',
          hidden: true
        }
      }
    },
    {
      fields: {
        name: {
          value: i18n.t(
            'pages.administration.peripheral-settings.tableCell.protocols'
          )
        },
        guid: {
          value: '/administration/peripheral-settings/protocols',
          hidden: true
        }
      }
    }
  ];

  return (
    <ContentBox>
      <ContentBoxItem>
        <TitleWrapper title="Peripheral Settings" />
      </ContentBoxItem>
      <TableBuilder rows={['Sub-menu']} cells={cells} onRowClick={onRowClick} />
    </ContentBox>
  );
};

export default Peripherals;

import type { ReactNode } from 'react';
import {
  AccordionSummary,
  Accordion as AccordionCore,
  Typography,
  AccordionDetails,
  AccordionProps
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import accordionStyles from 'styles/accordion';
import shortid from 'shortid';
import clsx from 'clsx';

interface AccordionI extends Omit<AccordionProps, 'id' | 'onChange'> {
  id?: number | string;
  icon?: ReactNode;
  title?: string;
  /** An alternative to a title if you need more control */
  headerComponent?: ReactNode;
  expandedId?: number | string;
  children: NonNullable<ReactNode>;
  onChange: (id: number | string, expanded: boolean) => void;
}

const Accordion: FunctionComponent<AccordionI> = ({
  id,
  icon,
  title,
  headerComponent,
  expandedId,
  children,
  onChange,
  className,
  ...props
}: AccordionI) => {
  const classes = accordionStyles();

  const [expandedState, setExpanded] = useState<
    number | string | boolean | undefined
  >(expandedId);

  const idRef = useRef<number | string>(id ?? shortid());

  const handleChange =
    (panel: number | string) =>
    (_: React.ChangeEvent<Record<string, unknown>>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : undefined);
      onChange(idRef.current, isExpanded);
    };

  useEffect(() => {
    setExpanded(expandedId);
  }, [expandedId]);

  return (
    <>
      <AccordionCore
        className={clsx(className, classes.accordion)}
        expanded={expandedState === idRef.current}
        onChange={handleChange(idRef.current)}
        {...props}
      >
        <AccordionSummary
          className={clsx([classes.summary, classes.title])}
          expandIcon={<ExpandMore />}
        >
          {headerComponent || [
            icon && icon,
            <Typography className={classes.title}>{title}</Typography>
          ]}
        </AccordionSummary>

        <AccordionDetails className={classes.details}>
          {Array.isArray(children) ? children.map(item => item) : children}
        </AccordionDetails>
      </AccordionCore>
    </>
  );
};

export default Accordion;

Accordion.defaultProps = {
  id: undefined,
  title: undefined,
  headerComponent: undefined,
  icon: undefined,
  expandedId: undefined
};

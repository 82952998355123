import { ChangeEvent, FunctionComponent, KeyboardEvent, useState } from 'react';
import { Box, Card, InputAdornment } from '@material-ui/core';
import { AccountCircle, Lock } from '@material-ui/icons';
import { updateFormData } from 'utils/form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import signInStyles from 'pages/signin/styles';
import validateForm from 'pages/signin/utils';
import LoadingButton from 'components/LoadingButton';
import i18n from 'i18n';
import TextField from 'components/TextField';

interface SignInFormI {
  onClick: (username: string, password: string) => void;
  isLoading: boolean;
}

const SignInForm: FunctionComponent<SignInFormI> = ({
  onClick,
  isLoading
}: SignInFormI) => {
  const [formData, setFormData] = useState<{
    username: string;
    password: string;
    errors: { [key: string]: boolean };
  }>({
    username: '',
    password: '',
    errors: { username: false, password: false }
  });

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedFormData = updateFormData(e, formData, validateForm);
    setFormData(updatedFormData);
  };

  const onKeyPress = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      onClick(formData.username, formData.password);
    }
  };

  const classes = signInStyles();

  const btnDisabled =
    !formData.username ||
    !formData.password ||
    !!formData.errors.username ||
    !!formData.errors.password;

  return (
    <Card raised elevation={8}>
      <Box
        display="flex"
        flexDirection="column"
        gridGap="16px"
        padding={3}
        onKeyPress={onKeyPress}
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label={i18n.t('pages.signin.formLabel.username')}
          helperText={formData.errors.username}
          name="username"
          onChange={onChangeField}
          error={!!formData?.errors?.username}
          autoComplete="username"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AccountCircle color="primary" />
              </InputAdornment>
            )
          }}
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label={i18n.t('pages.signin.formLabel.password')}
          type="password"
          id="password"
          helperText={formData.errors.password}
          error={!!formData?.errors?.password}
          onChange={onChangeField}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Lock color="primary" />
              </InputAdornment>
            )
          }}
        />
        <FormControlLabel
          className={classes.checkbox}
          control={<Checkbox value="remember" color="secondary" />}
          label={i18n.t('pages.signin.formLabel.rememberMe')}
        />
        <Box justifyContent="stretch" display="flex">
          <LoadingButton
            isLoading={isLoading}
            type="submit"
            label={i18n.t('button.signin')}
            fullWidth
            disabled={btnDisabled}
            variant="contained"
            color="secondary"
            onClick={() => onClick(formData.username, formData.password)}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default SignInForm;

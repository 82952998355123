import { useRef, memo } from 'react';
import JoditEditor from 'jodit-react';
import { IJodit, Jodit } from 'jodit';
import themeStyle from 'styles/theme';
import i18next from 'i18next';
import './Editor.css';

interface EditorI {
  text: string;
  field: string;
  onTextChange: (text: string, field: string) => void;
}

const Editor = memo<EditorI>(
  ({ text, onTextChange, field }: EditorI) => {
    const editor = useRef(null);

    const buttons = [
      'source',
      'paragraph',
      'bold',
      'italic',
      'ul',
      'ol',
      'fontsize',
      'brush',
      'image',
      'table',
      'link',
      'undo',
      'redo'
    ];

    const config: IJodit['options'] = {
      ...Jodit.defaultOptions,
      direction: i18next.dir(),
      style: {
        fontFamily: themeStyle.typography.fontFamily,
        fontSize: themeStyle.typography.mdRegular.fontSize
      },
      uploader: {
        ...Jodit.defaultOptions.uploader,
        insertImageAsBase64URI: true
      },
      buttonsXS: buttons,
      buttonsSM: buttons,
      buttonsMD: buttons,
      buttons,
      statusbar: false
    };

    return (
      <JoditEditor
        ref={editor}
        value={text}
        config={config}
        onBlur={newContent => onTextChange(newContent, field)} // preferred to use only this option to update the content for performance reasons
      />
    );
  },
  (prevProps, nextProps) => {
    return prevProps.text === nextProps.text;
  }
);

export default Editor;

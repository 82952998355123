import { makeStyles } from '@material-ui/core';

const titleStyles = makeStyles(theme => ({
  titleWrapper: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(2.5),
    height: 'fit-content',
    borderRadius: 12,
    overflowWrap: 'break-word',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleBar: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  }
}));

export default titleStyles;
